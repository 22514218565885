import PrivateRoute from "app/routes/middleware/PrivateRoute";
import Page from "@jumbo/shared/Page";
import ListParticularPlans from "app/pages/particularPlans/ListParticularPlans";

const particularPlansRoutes = [
  {
    path: "/plans/particular",
    element: (
      <PrivateRoute>
        <Page component={ListParticularPlans} />
      </PrivateRoute>
    ),
  },
];

export default particularPlansRoutes;
