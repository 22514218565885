import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import React from "react";
import ReactApexChart from "react-apexcharts";

const BarVertical = ({ width, title = "", style = null }) => {
  const { data } = useQuery(
    ["saleMonthVolume"],
    async () => {
      const axiosData = await brokersService.getDashboardSalesMonthVolumeData();
      return axiosData.data;
    },
    { staleTime: 60000 * 10 }
  );
  const dataStructure = {
    series: [{ name: "Volume", data: data?.map((item) => item.data) ?? [] }],
    options: {
      chart: {
        height: 150,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: ["#bf2f38", "#d1c62c", "#13d8aa"],
      plotOptions: {
        bar: {
          // columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: data?.map((item) => item.name) ?? [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => (val >= 1e3 && val < 1e6 ? val / 1e3 + "k" : val >= 1e6 ? val / 1e6 + "M" : val),
        },
      },
    },
  };
  return (
    <div id="chart" style={style}>
      <h4>{title}</h4>
      <ReactApexChart options={dataStructure.options} series={dataStructure.series} type="bar" width={width} />
    </div>
  );
};

export default BarVertical;
