import axios from "axios";

const brokersAxiosConfig = axios.create({
    baseURL:process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

brokersAxiosConfig.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token){
        config.headers.set('x-broker-key',token);
    }
    return config;
});

export default brokersAxiosConfig;