import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { colorForBgColor } from "@jumbo/utils";

const StyledChip = ({ label, bgColor, fontSize, ...Otherprops }) => {
  const color = colorForBgColor(bgColor);

  return (
    <Chip label={label} style={{ backgroundColor: bgColor, color: color }} />
  );
};
export default StyledChip;
