import { Delete, Download, Task } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Alert,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import brokersService from "app/services/apis/brokers";
import { useState } from "react";
import Dropzone from "react-dropzone";

const BeneficaryFileUpload = ({
  setFieldValue,
  index,
  values,
  fileType = "Bilhete de Identidade",
  type,
  disabled,
}) => {
  const [filesErrorMesg, setFilesErrorMesg] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const handleOndelete = (filename) => {
    const indexToDelete = values.findIndex(
      (file) => file.filename === filename
    );

    if (indexToDelete !== -1) {
      const updatedValues = [...values];
      updatedValues.splice(indexToDelete, 1);
      setFieldValue(`${type}[${index}].documents`, updatedValues);
    }
  };

  const uploadFiles = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      setIsLoadingFile(true);
      const { data } = await brokersService.uploadFiles(formData);

      const newDoc = {
        filename: data.filename,
        mask: data.filemask,
        document_type_id: 3,
      };

      const updatedDocuments = [...values, newDoc];
      setFieldValue(`${type}[${index}].documents`, updatedDocuments);
      setIsLoadingFile(false);
      setFilesErrorMesg(null);
    } catch (error) {
      setFilesErrorMesg(false);
      setFilesErrorMesg("Erro durante o upload:");
    }
  };

  const downloadFile = (filename) => {
    window.open(
      "https://app.internal.medicare.ao/api/brokers/sales/file/download?filename=" +
        filename
    );
  };
  const MediaMobile = useMediaQuery("(max-width:480px)");
  return (
    <Box width="100%" margin={0}>
      <Dropzone
        acceptedFiles=".jpg,.pdf,.jpeg,.png"
        multiple={true}
        maxFiles={1}
        disabled={values?.length >= 2 || disabled}
        onDrop={(acceptedFiles, rejectedFiles) => {
          /** neste caso será apenas 1 */
          if (acceptedFiles.length > 0) {
            uploadFiles(acceptedFiles[0]);
          } else if (rejectedFiles.length > 0) {
            setFilesErrorMesg(
              "Limite máximo de ficheiros é: 1x2 e os tipos permitidos (.jpg,.pdf,.jpeg,.png)"
            );
          } else {
            setFilesErrorMesg(null);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            border={`2.5px dashed #b0b0b0 `}
            p="1rem"
            sx={{
              "&:hover": { cursor: "pointer" },
              height: MediaMobile ? "90px" : "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: MediaMobile ? 2 : 4,
                alignItems: "center",
              }}
            >
              <FileUploadOutlinedIcon sx={{ fontSize: "20px" }} />
              <Typography sx={{ color: "#8E8E8E" }}>
                Anexar {fileType} (".jpg,.pdf,.jpeg,.png menos de 4MB")
              </Typography>
            </Box>
            <input {...getInputProps()} />
          </Box>
        )}
      </Dropzone>
      <Box style={{ paddingTop: "13px", width: "100%" }}>
        {filesErrorMesg !== null ? (
          <Alert severity="warning">{filesErrorMesg}</Alert>
        ) : null}
        {values?.length === 2 && (
          <Alert severity="warning">Atingiu limite máximo de ficheiros</Alert>
        )}
        <List>
          {values &&
            values.length > 0 &&
            values?.map((file, index) => (
              <ListItem
                key={index}
                sx={{
                  boxShadow:
                    "0px 0.49499985575675964px 0.9899997115135193px 0px #1018280F",
                  display: "flex",
                  gap: "14px",
                }}
              >
                <Task sx={{ color: "green", fontSize: "22px" }} />
                <ListItemText>{file.mask}</ListItemText>
                <IconButton
                  onClick={() => disabled ? downloadFile(file?.filename) :handleOndelete(file?.filename)}
                >
                   {disabled ? (
                    <Download sx={{ color: "#bf2f28", fontSize: "22px" }} />
                  ) : (
                    <Delete sx={{ color: "#bf2f28", fontSize: "22px" }} />
                  )}
                </IconButton>
              </ListItem>
            ))}
        </List>
        {isLoadingFile && <Skeleton sx={{ bgcolor: "#b0b0b0b0" }} />}
      </Box>
    </Box>
  );
};

export default BeneficaryFileUpload;
