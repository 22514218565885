import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MobileStepper,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IdentificationStep from "./IdentificationStep";
import BeneficiaryStep from "./BeneficiariesStep";
import PaymentStep from "./PaymentStep";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  CopyAll,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  SaveAlt,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import PlanStep from "./PlanStep";
import brokersService from "app/services/apis/brokers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { differenceInYears, isBefore, subYears } from "date-fns";
import { useTheme } from "@mui/material/styles";

const MySwal = withReactContent(Swal);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const AddPlanStepper = ({
  open,
  refetch,
  handleCloseModal,
  sale,
  is_submited,
  setIs_submited,
}) => {
  const theme = useTheme();

  const steps = [
    "Planos",
    "Identificação do Titular",
    "Beneficiários",
    "Pagamento",
  ];

  const maxSteps = steps.length;
  const [activeStep, setActiveStep] = useState(0);
  const [sale_id, setSale_id] = useState(sale??null);
  const [referenceData, setRefereceData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubimting, setIsSubimting] = useState(false);
  const [loadingContract, setLoadingContract] = useState(false);
  const [Initialbeneficiaries, setInitialBeneficiaries] = useState({
    isBeneficiary: false,
    beneficiaries: [],
    extras: [],
  });
  const [InitialCustomer, setInitialCustomer] = useState({
    customer_name: "",
    customer_birthdate: "",
    customer_gender: "",
    customer_address_city: "",
    customer_address_country_id: 6,
    customer_address_zone_id: "",
    customer_id_num: "",
    customer_vat: "", //000332033BO030
    customer_gsm: "",
    customer_iban: "",
    customer_email: "" /* "danilson.kayumbuca@medicare.ao" */,
    documents: [],
  });
  const [InitialPaymentValues, setInitialPaymentValues] = useState({
    payment_method_id: "",
    iban: "",
    phone_number: "",
    attachaments: {
      proposal: [],
      iban: [],
      comprovative: [],
    },
  });

  const planValidationSchema = yup.object({
    subscription_plan_id: yup.number().required("Selecione o plano."),
  });
  const identificationValidationSchema = yup.object({
    customer_address_zone_id: yup.number().required("Obrigatório."),

    customer_address_city: yup.number().when("beneficiary_country_id", {
      is: 6,
      then: yup.number().required("Obrigatório"),
      otherwise: yup.number(),
    }),
    customer_address_country_id: yup.number().required("Obrigatório."),
    customer_name: yup.string().required("Obrigatório"),
    customer_birthdate: yup
      .string()
      .required("Obrigatório.")
      .test(
        "Data-invalida-ze",
        "Precisa ser maior que 18 anos",
        (birthdate) => {
          const age = differenceInYears(new Date(), new Date(birthdate));
          return age >= 18;
        }
      ),
    customer_gender: yup.string().required("Obrigatório."),
    customer_address_country_id: yup.number().required("Obrigatório"),
    customer_id_num: yup.string().required("Obrigatório."),
    customer_gsm: yup.number().when(["customer_address_country_id"], {
      is: 6,
      then: yup
        .number()
        .required("Obrigatório")
        .min(900000000, "O mínimo é 9xxxxxxxx")
        .max(999999999, "O máximo é 9xxxxxxxx"),
      otherwise: yup.number().required("Obrigatório"),
    }),
    customer_email: yup
      .string()
      .required("Obrigatório.")
      .email("Deve ser email"),
  });

  const beneficiariesValidationSchema = yup.object().shape({
    beneficiaries: yup.array().of(
      yup.object().shape({
        beneficiary_country_id: yup.number().required("Obrigatório"),
        beneficiary_name: yup.string().required("Obrigatório"),
        beneficiary_id_number: yup.string().required("Obrigatório"),

        beneficiary_birthdate: yup.date().required("Obrigatório"),
        beneficiary_gsm: yup.string().nullable().when("beneficiary_birthdate", {
          is: (birthdate) => {
            const age = differenceInYears(new Date(), new Date(birthdate));
            return age >= 18;
          },
          then: yup.string().required("Obrigatório").min(9, "O mínimo é 9"),
          otherwise: yup.string().nullable(),
        }),

        beneficiary_gender: yup.string().required("Obrigatório"),
        beneficiary_address_zone_id: yup.number().required("Obrigatório"),
        beneficiary_address_city: yup.number().when("beneficiary_country_id", {
          is: 6,
          then: yup.number().required("Obrigatório"),
          otherwise: yup.number(),
        }),
        beneficiary_email: yup.string().email("Email inválido").nullable(),
      })
    ),
    extras: yup.array().of(
      yup.object().shape({
        beneficiary_country_id: yup.number().required("Obrigatório"),
        beneficiary_name: yup.string().required("Obrigatório"),
        beneficiary_id_number: yup.string().required("Obrigatório"),
        beneficiary_birthdate: yup.date().required("Obrigatório"),
        beneficiary_gsm: yup.string().nullable().when("beneficiary_birthdate", {
          is: (birthdate) => {
            const age = differenceInYears(new Date(), new Date(birthdate));
            return age >= 18;
          },
          then: yup.string().required("Obrigatório").min(9, "O mínimo é 9"),
          otherwise: yup.string().nullable(),
        }),
        beneficiary_gender: yup.string().required("Obrigatório"),
        beneficiary_address_zone_id: yup.number().required("Obrigatório"),
        beneficiary_address_city: yup.number().when("beneficiary_country_id", {
          is: 6,
          then: yup.number().required("Obrigatório"),
          otherwise: yup.number(),
        }),
        beneficiary_email: yup.string().email("Email inválido").nullable(),
      })
    ),
  });

  const paymentValidationSchema = yup.object().shape({
    iban: yup.string().when("payment_method_id", {
      is: 6,
      then: yup
        .string()
        .required("Obrigatório")
        .min(26, "iban Inválido")
        .max(26, "Iban Inválido"),
      otherwise: yup.string(),
    }),
    phone_number: yup.string().when("payment_method_id", {
      is: 4,
      then: yup
        .string()
        .required("Contacto Obrigatório"),
      otherwise: yup.string(),
    }),
  });

  //********************************************************************************************************************************* */
  //*  F O R M I K
  //********************************************************************************************************************************* */

  const formikIdentification = useFormik({
    enableReinitialize: true,
    initialValues: InitialCustomer,
    validationSchema:is_submited? yup.object({}):identificationValidationSchema,
    onSubmit: (values) => {
      if(!is_submited){
        if (values.documents.length < 1) {
          toast.error("Não carregou o documento de identificação!.");
        } else {
          handleNext();
        }
      }else{
        handleNext();
      }
    },
  });

  const formikAux = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_saving: false,
    },
  });

  const formikPlan = useFormik({
    enableReinitialize: true,
    initialValues: {
      subscription_plan_id: "",
      max_family_members: "",
      max_extra_members: "",
      sale_id:""
    },
    validationSchema:is_submited?yup.object({}):planValidationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const formikBeneficiaries = useFormik({
    enableReinitialize: true,
    initialValues: Initialbeneficiaries,
    validationSchema:is_submited?yup.object({}):beneficiariesValidationSchema,
    onSubmit: (values) => {
      if(!is_submited){
          if (formikBeneficiaries.errors?.length > 0) {
            toast.error("Tem dados não preenchidos.");
          } else {
          handleNext();
          }
      }else{
        handleNext();
      }
    },
  });

  const formikPayment = useFormik({
    enableReinitialize: true,
    initialValues: InitialPaymentValues,
    validationSchema:is_submited?yup.object({}):paymentValidationSchema,
    onSubmit: (values) => {
      if(!is_submited){
        console.log(values);
        console.log("Here");
        
        if(values.payment_method_id == 6 || values.payment_method_id ==4){
          if(values.payment_method_id == 6 && values?.attachaments?.iban?.length == 0){
            toast.error("Carregue o comprovativo de IBAN");
          }else if (values?.attachaments?.proposal?.length == 0 ){
            toast.error("Carregue a proposta de adesão");
            //toast.error("Carregue a  autorização de débito");
          } else {
            handleFormSubmit();
          }

        }
        /*
        if (values.payment_method_id == 6 || values.payment_method_id ==4) {
          if (
            values?.attachaments?.debitAuthorization?.length < 1 &&
            values?.attachaments?.proposal?.length < 1
          ) {
            toast.error("Carregue a proposta de adesão");
            toast.error("Carregue a  autorização de débito");
          } else {
            handleFormSubmit();
          }
        } else if (values.payment_method_id == 1) {
          if (values.attachaments.comprovative.length < 1) {
            toast.error("Carregue o comprovativo de pagamento");
          } else {
            handleFormSubmit();
          }
        } else {
          handleFormSubmit();
        }*/

      }else{
        handleNext();
      }
    },
  });

  //********************************************************************************************************************************* */
  //*  H A N D L E   S T E P P E R
  //********************************************************************************************************************************* */

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    switch (activeStep) {
      case 0:
        formikPlan.handleSubmit();
        break;
      case 1:
        formikIdentification.handleSubmit();
        break;
      case 2:
        formikBeneficiaries.handleSubmit();
        break;
      case 3:
        formikPayment.handleSubmit();
        break;
    }
  };

  const handleFormSubmit = async () => {
    setIsSubimting(true);

    const payload = {};
    payload.subscription_plan_id = formikPlan.values.subscription_plan_id;
    payload.sale_id = formikPlan.values.sale_id;
    payload.payment_method_id = formikPayment.values.payment_method_id;
    payload.custom_telephone = formikPayment.values.phone_number ?? formikIdentification.values.customer_gsm;
    payload.iban = formikPayment.values.iban;
    payload.customer = formikIdentification.values;
    payload.customer.customer_iban = formikPayment.values.iban;
    payload.customer.isBeneficiary = formikBeneficiaries.values.isBeneficiary;
    payload.beneficiaries = formikBeneficiaries.values.beneficiaries;
    payload.extras = formikBeneficiaries.values.extras;
    if (formikPayment.values.payment_method_id == 6 || formikPayment.values.payment_method_id == 4 ) {
      payload.attachments = [
        formikPayment.values.attachaments?.proposal[0]??[],
        formikPayment.values.attachaments?.iban[0]??[],
      ];
    } else {
        payload.attachments = formikPayment.values.attachaments.comprovative;
    }

    try {
      const { data } = await brokersService.createSales(payload);
      setIsSubimting(false);
      MySwal.fire({
        position: "center",
        icon: "success",
        title: "Contrato salvo com sucesso!",
        showConfirmButton: true,
        timer: 3000,
      });
      refetch();

      if (payload.payment_method_id == 4) {
        setRefereceData(data?.multicaixa_reference ?? null);
        setIs_submited(true);
        setIsSubimting(false);
      } else {
        handleCloseModal();
      }
    } catch (error) {
      setIsSubimting(false);
      toast.error("Erro ao Submeter! Tente novamente..!");
    }
    setIsSubimting(false);
  };

  const handleFormSave = async (paymentMethodID=null) => {
    
    //setIsSaving(false);
    formikAux.setFieldValue('is_saving',true);
    
    const payload = {};

    payload.sale_id = formikPlan.values.sale_id || null;
    payload.subscription_plan_id = formikPlan.values.subscription_plan_id;
    payload.payment_method_id = formikPayment.values.payment_method_id;
    payload.iban = formikPayment.values.iban;
    payload.customer = formikIdentification.values;
    payload.customer.customer_iban = formikPayment.values.iban;
    payload.customer.isBeneficiary = formikBeneficiaries.values.isBeneficiary;
    payload.beneficiaries = formikBeneficiaries.values.beneficiaries;
    payload.extras = formikBeneficiaries.values.extras;

    if(paymentMethodID){
      payload.payment_method_id = paymentMethodID;
    }
    if (formikPayment.values.payment_method_id == 6) {
      payload.attachments = [
        formikPayment.values.attachaments.proposal[0],
        formikPayment.values.attachaments.iban[0],
      ];
    } else {
      payload.attachments = formikPayment.values.attachaments.comprovative;
    }

    try {
      const { data } = await brokersService.save(payload);
      toast.success("Contrato salvo com sucesso..!");
      //setSale_id(data.sale_id);
      formikPlan.setFieldValue('sale_id',data.sale_id);
      //setIsSaving(false);
      formikAux.setFieldValue('is_saving',false);
      refetch();
    } catch (error) {
      toast.error("Falha ao salvar contrato..!");
      //setIsSaving(false);
      formikAux.setFieldValue('is_saving',false);
    }

    //setIsSaving(false);
  };

  const getData = async () => {
    if (sale_id !== null) {
      setLoadingContract(true);
      const axiosData = await brokersService.getSaleSaved(sale_id);
      if (axiosData && axiosData.data !== null) {
        const { customer, beneficiaries, extras, attachments } = axiosData.data;

        formikPlan.setFieldValue(
          "subscription_plan_id",
          axiosData.data.subscription_plan_id
        );
        formikPlan.setFieldValue(
          "sale_id",
          axiosData.data.sale_id
        );

        setInitialCustomer({
          customer_name: customer.customer_name,
          customer_birthdate: customer.customer_birthdate ?? "",
          customer_gender: customer.customer_gender ?? "",
          customer_address_city: customer.customer_address_city ?? "",
          customer_address_country_id:customer.customer_address_country_id ?? "",
          customer_address_zone_id: customer.customer_address_zone_id ?? "",
          customer_id_num: customer.customer_id_num,
          customer_vat: customer.customer_id_num ?? "",
          customer_email: customer.customer_email ?? "",
          customer_gsm: customer.customer_gsm ?? "",
          customer_iban: customer.customer_iban ?? "",
          documents: customer.documents,
        });

        setInitialBeneficiaries({
          isBeneficiary: customer.isBeneficiary == 0 ? false : true,
          beneficiaries: beneficiaries,
          extras: extras,
        });

        setInitialPaymentValues({
          phone_number: axiosData?.data?.phone_number ?? customer.customer_gsm,
          iban:customer.customer_iban ?? "",
          payment_method_id: axiosData.data.payment_method_id,
          attachaments:
            axiosData.data.payment_method_id === 6 || axiosData.data.payment_method_id === 4
              ? {
                  proposal: [
                    ...(attachments
                      ?.filter((attach) => attach?.document_type_id === 1)
                      ?.filter(Boolean) || []),
                  ],
                  iban: [
                    ...(attachments
                      ?.filter((attach) => attach?.document_type_id === 99)
                      ?.filter(Boolean) || []),
                  ],
                }
              : {
                  comprovative: attachments,
                },
        });

          setRefereceData(axiosData?.data?.multicaixa_reference ?? null);

        setLoadingContract(false);
      } else {
        setLoadingContract(false);
        toast.error("Contrato não pode ser editado");
      }
    }
  };

  useEffect(() => {
      getData();
  }, [sale_id]);

  const getStepContent = (step) => {
    const allSteps = [
      <PlanStep
        handleNext={handleNext}
        formik={formikPlan}
        is_submited={is_submited}
      />,
      <IdentificationStep
        handleNext={handleNext}
        formik={formikIdentification}
        beneficiaries={formikBeneficiaries}
        payment={formikPayment}
        is_submited={is_submited}
      />,
      <BeneficiaryStep
        handleNext={handleNext}
        formik={formikBeneficiaries}
        plan={formikPlan}
        titular={formikIdentification}
        is_submited={is_submited}
      />,
      <PaymentStep
        formik={formikPayment}
        plan={formikPlan}
        beneficiaries={formikBeneficiaries}
        is_submited={is_submited}
        maxBaseMembers={formikPlan.values.max_family_members}
        titular={formikIdentification}
        referenceData={referenceData}
        save={handleFormSave}
      />,
    ];

    return (
      <>
        {allSteps.map((stepCmp, index) => {
          return <div hidden={index !== step}>{stepCmp}</div>;
        })}
      </>
    );
  };
  const MediaMobile = useMediaQuery("(max-width:480px)");

  return (
    <StyledDialog
      open={open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      TransitionComponent={Transition}
      maxWidth={"lg"}
      maxHeight={"470px"}
    >
      {MediaMobile ? (
        <DialogTitle
          sx={{
            backgroundColor: "#bf2f38",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {is_submited ? (
            loadingContract ? (
              <Typography color="white" fontWeight={400} fontSize={14}>
                CARREGANDO CONTRATO...
              </Typography>
            ) : (
              <>
                <Typography
                  color="white"
                  fontWeight={400}
                  fontSize={14}
                  textTransform={"uppercase"}
                >
                  CONTRATO - {sale_id}
                </Typography>
              </>
            )
          ) : (
            <Typography color="white" fontWeight={400} fontSize={14}>
              NOVO CONTRATO
            </Typography>
          )}
          <Tooltip title="Fechar">
            <IconButton
              aria-label="close"
              sx={{
                color: "white",
                padding: 0,
              }}
              size="large"
            >
              <CloseIcon onClick={handleCloseModal} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
      ) : (
        <DialogTitle
          sx={{
            backgroundColor: "#bf2f38",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {is_submited ? (
            loadingContract ? (
              <Typography color="white" fontWeight={400} fontSize={18}>
                CARREGANDO CONTRATO...
              </Typography>
            ) : (
              <>
                <Typography
                  color="white"
                  fontWeight={400}
                  fontSize={18}
                  textTransform={"uppercase"}
                >
                  CONTRATO -{" "}
                  {sale_id !== null
                    ? formikIdentification.values.customer_name + " - " + sale_id
                    : formikIdentification.values.customer_name}
                  {}
                </Typography>
              </>
            )
          ) : (
            <Typography color="white" fontWeight={400} fontSize={18}>
              NOVO CONTRATO
            </Typography>
          )}

          <Divider />
          <Tooltip title="Fechar">
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
              size="large"
            >
              <CloseIcon onClick={handleCloseModal} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
      )}

      <Box width="100%" sx={{ paddingX: "25px", paddingY: "5px" }}></Box>
      {loadingContract ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <CircularProgress size={24} />
        </DialogContent>
      ) : MediaMobile ? (
        <DialogContent>
          <Box sx={{ minHeight: 300 }}>{getStepContent(activeStep)}</Box>

          <MobileStepper
            position="static"
            activeStep={activeStep}
            steps={steps.length}
            sx={{
              width: "100%",
              backgroundColor: "white",
              padding: "0",
              margin: "0",
            }}
            nextButton={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  size="small"
                  onClick={handleFormSave}
                  disabled={is_submited}
                >
                  <SaveAlt />
                </Button>
                <Button
                  size="small"
                  onClick={handleSubmit}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </Box>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </DialogContent>
      ) : (
        <DialogContent>
          <Box sx={{ width: "100%", paddingTop: "9px" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box sx={{ minHeight: 300 }}>{getStepContent(activeStep)}</Box>
        </DialogContent>
      )}

      {MediaMobile ? null : (
        <DialogActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {activeStep > 0 && (
              <Button
                type="submit"
                form="add-User"
                disableElevation // remove boxShadow
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingX: "20px",
                  paddingY: "8px",
                }}
                onClick={() => setActiveStep((prev) => prev - 1)}
              >
                <ChevronLeft sx={{ paddingBottom: "4px" }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
                  Voltar
                </Typography>
              </Button>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "16px",
              }}
            >
              {activeStep >= 2 ? (
                <Button
                  type="submit"
                  form="add-User"
                  disableElevation
                  disabled={is_submited?true:formikPayment.values.payment_method_id>0?false:true}
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingX: "20px",
                    paddingY: "8px",
                    backgroundColor: "#64748B",
                    ":hover": {
                      backgroundColor: "#485567",
                    },
                  }}
                  onClick={() => handleFormSave()}
                >
                  {formikAux.values.is_saving? (
                    <CircularProgress size={18} sx={{ color: "white" }} />
                  ) : (
                    <>
                      <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
                        Gravar
                      </Typography>
                      <Save sx={{ paddingBottom: "4px" }} />
                    </>
                  )}
                </Button>
              ) : null}

              <Button
                type="submit"
                form="add-User"
                disabled={activeStep > 2 && is_submited}
                disableElevation
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingX: "20px",
                  paddingY: "8px",
                }}
                onClick={() => handleSubmit()}
              >
                {isSubimting ? (
                  <CircularProgress size={18} sx={{ color: "white" }} />
                ) : (
                  <>
                    <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
                      {activeStep > 2 ? "Submeter" : "Proximo"}
                    </Typography>
                    <ChevronRight sx={{ paddingBottom: "4px" }} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      )}
    </StyledDialog>
  );
};
export default AddPlanStepper;
