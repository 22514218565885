import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import React from "react";
import usePlanCalculation from "./usePlanCalculation";
import CssTextField from "app/components/CssTextField";
import FileUpload from "../FileUpload";


const handleInputChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");

    const formattedNumber = inputNumber.replace(/(\d{4})(?=\d)/g, "$1-");

    return formattedNumber;
};
const DirectDebit = (props) => {
  const { data, isLoading } = usePlanCalculation(props.extras,props.planID);
  return (
    <>
      <Grid item xs={12} sm={6} spacing={2}>
        <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
          IBAN
        </Typography>
        <CssTextField
          fullWidth
          name="iban"
          id="iban"
          size="small"
          type="text"
          inputProps={{ maxLength: 26, readOnly:props.submited }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    color: "gray",
                    fontSize: "15px",
                    paddingRight: "1px",
                  }}
                >
                  AO06
                </Box>
              </InputAdornment>
            ),
          }}
          value={props.formik.values.iban}
          color="primary"
          sx={{ borderColor: "red" }}
          onChange={(e) => {
            props.formik.setFieldValue("iban", handleInputChange(e));
          }}
          error={
            props.formik.touched.iban &&
            Boolean(props.formik.errors.iban)
          }
          helperText={
            props.formik.touched.iban &&
            props.formik.errors.iban
          }
        />
      <Box mt={2} />
        <Box
              sx={{
                display: "flex",
                gap: "5px",
                width: "100%",
                paddingBottom: "10px",
              }}
            >
              <Typography variant="subtitle2">
                Comprovativo de IBAN
              </Typography>
              <Typography sx={{ color: "#d35400" }}>( Obrigatório)</Typography>
            </Box>
            <FileUpload
              setFieldValue={props.formik.setFieldValue}
              values={props.formik.values.attachaments?.iban}
              fileType="documentos"
              type={"iban"}
              document_type_id={99}
              disabled={props.submited}
            />
        <Box
          style={{
            height: "300px",
          }}
        >
          <img
            src={data?.card_img}
            style={{
              height: "100%",
              objectFit: "cover",
              objectPosition: "cover",
            }}
          />
        </Box>
      </Grid>
      <Grid item sx={12} sm={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                width: "100%",
                paddingBottom: "10px",
              }}
            >
              <Typography variant="subtitle2">
                Proposta de Adesão com débito direto
              </Typography>
              <Typography sx={{ color: "#d35400" }}>( Obrigatório)</Typography>
            </Box>
            <FileUpload
              setFieldValue={props.formik.setFieldValue}
              values={props.formik.values.attachaments?.proposal}
              fileType="documentos"
              type={"proposal"}
              document_type_id={1}
              disabled={props.submited}
            />
            <Box mt={1} />
            {isLoading ? (
          <Typography textAlign={"center"}>Atualizando</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              textAlign: "justify",
            }}
          >
            <Typography>
              <strong>Plano base</strong>:{" "}
              {props.beneficiaries?.values?.beneficiaries.length}{" "}
              beneficiário(s) de {props.maxBaseMembers} incluído (s) :
              <span style={{ color: "#bf2f38", fontWeight: "500" }}>
                {" " + data?.base_value + " kz "}
              </span>
              / {data?.billing_cycle_unit_formatted}
            </Typography>
            {props.beneficiaries?.values?.extras.length > 0 ? (
              <Typography>
                <strong>Beneficiários Extras </strong>:{" "}
                {props.beneficiaries?.values?.extras.length} *
                {" " + data?.extra_member_amount + " kz "} :{" "}
                <span style={{ color: "#bf2f38", fontWeight: "500" }}>
                  {" " + data?.total_extra_amount + " kz "}
                </span>
                / {data?.billing_cycle_unit_formatted}
              </Typography>
            ) : null}

            <Typography variant="h4" marginTop={2}>
              Total a Debitar {data?.billing_cycle_unit_formatted2}{" "}
              <span style={{ color: "#bf2f38" }}>
                {" " + data?.billing + " kz "}
              </span>
              / {data?.billing_cycle_unit_formatted}
            </Typography>
          </Box>
        )}
          </Grid>
    </>
  );
};

export default DirectDebit;
