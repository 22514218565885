import WalletPage from "./Pages/WalletPage";

const WalletRoute = [
  {
    path: "/wallet",
    element: <WalletPage />,
  },
];

export default WalletRoute;
