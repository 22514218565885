import { Add, ClearAll, Edit } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useMemo, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import AddPlanStepper from "./components/AddPlanStepper";
import brokersService from "app/services/apis/brokers";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useCustomQuery } from "app/hooks/useCustomQuery";
import { useQuery } from "@tanstack/react-query";
import useAuth from "app/hooks/useAuth";
import DatePickerField from "@jumbo/components/DatePickerField";
import StyledChip from "app/components/ContentLoader/styledChip";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import GetAppIcon from '@mui/icons-material/GetApp';

const ListParticularPlans = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [customerName, setCustomerName] = useState("");
  const [seller, setSeller] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [contact, setContact] = useState("");
  const [document, setDocument] = useState("");
  const [status, setStatus] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [sale_id, setSale_id] = useState(null);
  const [is_submited, setIs_submited] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [columnsHide, setColumnsHide] = useState({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAuth();

  useState(() => {
    setColumnsHide({ customer_email: true, payment_method_name: true });
  }, [matches]);

  const { data: payments } = useQuery(
    ["payement-data"],
    async () => {
      const axiosData = await brokersService.paymentMethods();
      return axiosData.data;
    },
    { staleTime: 60000 * 5 }
  );

  const {
    data: sales,
    isError,
    isFetching,
    refetch,
    isLoading,
  } = useCustomQuery(
    [
      "sales-data",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      customerName,
      seller,
      paymentMethod,
      contact,
      status,
      startDate,
      endDate,
      document,
    ],
    async () => {
      console.log(sorting);
      const axiosData = await brokersService.getSales(
        pagination,
        sorting,
        customerName,
        paymentMethod,
        contact,
        0,
        status,
        0,
        seller,
        startDate,
        endDate,
        document
      );
      return axiosData.data;
    },
    { debounce: 350, keepPreviousData: true, staleTime: 60000 * 5 }
  );

  const columns = useMemo(
    () => [
      {
        id: "sale_id",
        accessorKey: "sale_id",
        header: "ID",
        size: 60,
      },
      {
        id: "customer_name",
        accessorKey: "customer_name",
        header: "NOME",
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "customer_gsm",
        accessorKey: "customer_gsm",
        header: "CONTACTO",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "subscription_plan_name",
        accessorKey: "subscription_plan_name",
        header: "CONTRATO",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "seller_name",
        accessorKey: "seller_name",
        header: "GESTOR COMERCIAL",
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "payment_method_name",
        accessorKey: "payment_method_name",
        header: "MÉTODO DE PAGAMENTO",
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: "Valor",
        footer: "Total",
        Footer: () => (
          <>
            <Stack direction={"row"} spacing={1}>
              <span>Total:</span>
              <Box color="success.dark">{sales?.totalRowAmount}</Box>
            </Stack>
          </>
        ),
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "payed_at",
        accessorKey: "amount",
        header: "Pago",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Box
            sx={{
              border: "none",
              borderRadius: 1.5,
              padding: 0.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {row.original.is_payed ? (
              <Tooltip>
                <CheckIcon sx={{ color: "#4BB543" }} />
              </Tooltip>
            ) : (
              <Tooltip>
                <ErrorIcon sx={{ color: "red" }} />
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        id: "status",
        accessorKey: "sale_status",
        header: "ESTADO",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Box
            sx={{
              border: "none",
              borderRadius: 1.5,
              padding: 0.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {row.original.sale_status.toLowerCase() == "pendente" ? (
              <StyledChip bgColor={"#FFD700"} label={"Pendente"} />
            ) : null}
            {row.original.sale_status.toLowerCase() == "validado" ? (
              <StyledChip bgColor={"#4BB543"} label={"Validado"} />
            ) : null}
            {row.original.sale_status.toLowerCase() == "submetido" ? (
              <StyledChip bgColor={"#0097ff"} label={"Submetido"} />
            ) : null}
          </Box>
        ),
        footer: "Total",
        Footer: () => (
          <>
            <Stack direction={"row"} spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <span>Validados:</span>
                <Box color="success.dark">{sales?.totalValidated}</Box>
              </Stack>
              <Typography>|</Typography>
              <Stack direction={"row"} spacing={1}>
                <span>Por Validar:</span>
                <Box color="warning.main">{sales?.totalPending}</Box>
              </Stack>
            </Stack>
          </>
        ),
      },
      {
        id: "date_added",
        accessorKey: "date_added",
        header: "DATA",
        size: 100,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    [sales]
  );

  const breadcrumb = (() => {
    const crumbs = {
      links: [
        {
          name: "Página Inicial",
          link: "/dashboard",
        },
      ],
      active: {
        name: "Planos Particulares",
      },
    };
    return (
      <Breadcrumbs aria-label="breadcrumb" color={"common.black"}>
        {crumbs.links.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();

  const clearFilters = () => {
    setCustomerName("");
    setPaymentMethod(0);
    setContact("");
    setSeller("");
    setStatus("");
    setStartDate("");
    setEndDate("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSale_id(null);
    setIs_submited(null);
  };


  const exportCSV = async ()=>{
    await brokersService.exportSalesCSV(
      pagination,
      sorting,
      customerName,
      paymentMethod,
      contact,
      0,
      status,
      0,
      seller,
      startDate,
      endDate,
      document);
  }

  const MediaMobile = useMediaQuery("(max-width:480px)");
  const MediaDesktop = useMediaQuery("(max-width:480px)");

  return (
    <JumboCardQuick
      title={
        <Typography
          variant={"h3"}
          style={{ fontWeight: "bold" }}
          color={"common.black"}
          mb={0}
        >
          Planos Particulares
        </Typography>
      }
      action={MediaMobile ? null : breadcrumb}
      bgColor={"#ffff"}
      noWrapper
      sx={{
        height: "100%",
        boxShadow: "0",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <JumboCardQuick sx={{ p: 2, boxShadow: 0 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: MediaMobile ? "start" : "end",
            }}
          >
            <Button
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
              variant="contained"
              onClick={() => setOpenModal(true)}
              size={"small"}
            >
              <Add />
              Novo Contrato
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ m: 2 }}>
            <MaterialReactTable
              columns={columns}
              initialState={{
                showColumnFilters: false,
                density: "compact",
                columnVisibility: columnsHide,
              }}
              data={sales?.rows ?? []}
              enableRowActions
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "4px",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButton
                    disabled={!row.original.can_edit}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setSale_id(row.original.sale_id);
                      setOpenModal(true);
                    }}
                  >
                    <Edit />
                  </IconButton>

                  <IconButton
                    disabled={!row.original.is_submited}
                    size="small"
                    onClick={() => {
                      setSale_id(row.original.sale_id);
                      setIs_submited(
                        row.original.is_submited == 1 ? true : false
                      );
                      setOpenModal(true);
                    }}
                  >
                    <VisibilityIcon
                      sx={{
                        color:
                          row.original.is_submited == 1 ? "#2B5BA9" : "#808080",
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              enableRowSelection={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableColumnOrdering={false}
              enableColumnFilterMode={false}
              enableColumnResizing={true}
              enableStickyFooter
              enableHiding={true}
              enableFilters={false}
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                },
              }}
              rowCount={sales?.totalRowCount ?? 0}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isFetching,
                sorting,
              }}
              muiTableHeadCellProps={{
                sx: {
                  border: "1px solid #eee",
                  width: "max-content",
                },
                "& th:last-child": {
                  width: "20px",
                  border: "1px solid blue",
                },
              }}
              muiTableProps={{
                sx: {
                  border: "1px solid #eee",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "1px solid #F4F4F7",
                },
              }}
              muiToolbarAlertBannerProps={
                isError
                  ? {
                      color: "error",
                      children: "Erro ao tentar carregar lista.",
                    }
                  : undefined
              }
              muiTableBodyRowProps={{ hover: false }}
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
              muiTablePaginationProps={{ sx: { backgroundColor: "#FFFFFF" } }}
              muiBottomToolbarProps={{ sx: { backgroundColor: "#FFFFFF" } }}
              muiTopToolbarProps={{
                sx: { backgroundColor: "white", marginBottom: "25px" },
              }}
              muiTableHeadRowProps={{
                sx: {
                  background: "#fff",
                  "& .MuiTableCell-head": {
                    color: "#000",
                    textTransform: "uppercase",
                  },
                  border: "0.5px solid #eee",
                },
              }}
              enableTableHead={true}
              muiTablePaperProps={{ sx: { boxShadow: "none" } }}
              manualFiltering
              manualPagination
              manualSorting
              localization={MRT_Localization_PT_BR} //lang
              muiTableBodyProps={{
                sx: (theme) => ({
                  "& tr": {
                    backgroundColor: "#FFF",
                  },
                  "& tr > td": {
                    border: "0.5px solid #eee",
                  },
                }),
              }}
              /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
              renderTopToolbarCustomActions={({ table }) => {
                return (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: expandFilters === true ? "2px" : "0px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                          <IconButton
                            onClick={() => {
                              setExpandFilters((prev) => !prev);
                            }}
                          >
                            <FilterListIcon
                              sx={{ fontSize: 20, color: "#000" }}
                            />
                          </IconButton>
                          <Typography sx={{ color: "#000" }}>
                            Filtrar por
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Exportar CSV">
                            <IconButton disabled={sales?.rows?.length==0} onClick={() => {
                                exportCSV()
                            }}>
                              <GetAppIcon sx={{ color: sales?.rows?.length==0? 'grey': "#4BB543" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={2}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              name="name"
                              defaultValue=""
                              label="Nome"
                              value={customerName}
                              size="small"
                              onChange={(ev) =>
                                setCustomerName(ev.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6} sm={1.5}>
                            <TextField
                              variant="outlined"
                              type="number"
                              name="contact"
                              fullWidth
                              defaultValue=""
                              label="Telefone"
                              value={contact}
                              size="small"
                              onChange={(ev) => setContact(ev.target.value)}
                            />
                          </Grid>

                          <Grid item xs={6} sm={1.5}>
                            <TextField
                              variant="outlined"
                              type="text"
                              name="document"
                              fullWidth
                              defaultValue=""
                              label="Documento de Identificação"
                              value={document}
                              size="small"
                              onChange={(ev) => setDocument(ev.target.value)}
                            />
                          </Grid>

                          <Grid item xs={6} sm={1.5}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              select
                              name="payment_method"
                              label="Método de pagamento"
                              value={paymentMethod}
                              size="small"
                              onChange={(ev) =>
                                setPaymentMethod(ev.target.value)
                              }
                            >
                              <MenuItem value={0}>Todos</MenuItem>
                              {payments?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          {user?.has_children == 1 ? (
                            <Grid item xs={6} sm={1.5}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                select
                                defaultValue=""
                                name="seller"
                                label="Gestor comercial"
                                value={seller}
                                size="small"
                                onChange={(ev) => setSeller(ev.target.value)}
                              >
                                <MenuItem value={0}>Todos</MenuItem>
                                {user?.childrens?.map((item) => (
                                  <MenuItem
                                    key={item.entity_id}
                                    value={item.entity_id}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          ) : null}

                          <Grid item xs={6} sm={1}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              select
                              name="status"
                              label="Estado"
                              value={status}
                              size="small"
                              onChange={(ev) => setStatus(ev.target.value)}
                            >
                              <MenuItem value={0}>Todos</MenuItem>
                              <MenuItem value={1}>Pendente</MenuItem>
                              <MenuItem value={2}>Submetido</MenuItem>
                              <MenuItem value={3}>Validado</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item md={1.25}>
                            <DatePickerField
                              fullWidth
                              size="small"
                              label="De"
                              value={startDate}
                              onChange={(e) =>
                                setStartDate(
                                  e.target.value.split("/").reverse().join("-")
                                )
                              }
                            />
                          </Grid>
                          <Grid item md={1.25}>
                            <DatePickerField
                              fullWidth
                              size="small"
                              label="Até"
                              value={endDate}
                              onChange={(e) =>
                                setEndDate(
                                  e.target.value.split("/").reverse().join("-")
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={0.5}>
                            <Tooltip title="Limpar Filtros">
                              <IconButton
                                onClick={() => {
                                  clearFilters();
                                }}
                              >
                                <FilterAltOffIcon sx={{ color: "#000" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Grid>
        </Grid>
      </JumboCardQuick>

      {openModal && (
        <AddPlanStepper
          open={openModal}
          refetch={refetch}
          handleCloseModal={handleCloseModal}
          sale={sale_id}
          is_submited={is_submited}
          setIs_submited={setIs_submited}
        />
      )}
    </JumboCardQuick>
  );
};

export default ListParticularPlans;
