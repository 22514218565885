import { Add, ClearAll, Edit } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Collapse,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useMemo, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import brokersService from "app/services/apis/brokers";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useCustomQuery } from "app/hooks/useCustomQuery";
import { useQuery } from "@tanstack/react-query";

const ListSales = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [customerName, setCustomerName] = useState("");
  const [seller, setSeller] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [contact, setContact] = useState("");
  const [status, setStatus] = useState(0);

  const columns = useMemo(
    () => [
      {
        id: "sale_id",
        accessorKey: "sale_id",
        header: "ID",
        size: 20,
      },
      {
        id: "customer_name",
        accessorKey: "customer_name",
        header: "NOME",
      },
      {
        id: "customer_gsm",
        accessorKey: "customer_gsm",
        header: "CONTACTO",
        enableSorting: false,
        size: 20,
      },
      {
        id: "customer_email",
        accessorKey: "customer_email",
        header: "EMAIL",
        enableSorting: false,
      },
      {
        id: "subscription_plan_name",
        accessorKey: "subscription_plan_name",
        header: "CONTRATO",
        enableSorting: false,
      },
      {
        id: "payment_method_name",
        accessorKey: "payment_method_name",
        header: "MÉTODO DE PAGAMENTO",
        size: 20,
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "DATA",
        size: 20,
      },
    ],
    []
  );

  const breadcrumb = (() => {
    const crumbs = {
      links: [
        {
          name: "Página Inicial",
          link: "/dashboard",
        },
      ],
      active: {
        name: "Planos Particulares",
      },
    };
    return (
      <Breadcrumbs aria-label="breadcrumb" color={"common.black"}>
        {crumbs.links.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();

  const clearFilters = () => {
    setCustomerName("");
    setContact("");
  };




  const {
    data: sales,
    isError,
    isFetching,
    isLoading,
  } = useCustomQuery(
    [
      "sales-list-data",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      customerName,
      seller,
      paymentMethod,
      contact,
      status,
    ],
    async () => {
      console.log(sorting);
      const axiosData = await brokersService.getSales(
        pagination,
        sorting,
        customerName,
        paymentMethod,
        contact,
        1,
        0,
        1
      );
      return axiosData.data;
    },
    { debounce: 350, keepPreviousData: true, staleTime: 60000 * 5 }
  );

  return (
    <JumboCardQuick
      title={
        <Typography
          variant={"h3"}
          style={{ fontWeight: "bold" }}
          color={"common.black"}
          mb={0}
        >
          Vendas
        </Typography>
      }
      action={breadcrumb}
      bgColor={"#ffff"}
      noWrapper
      sx={{
        height: "100%",
        boxShadow: "0",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ paddingLeft: "2rem", paddingRight: "3rem" }}
      >
        <Grid item marginTop={2} xs={12} sx={{ display: "flex", justifyContent:"end" }}>
        </Grid>
        <Grid item xs={12} sx={{ m: 2 }}>
          <MaterialReactTable
            columns={columns}
            initialState={{ showColumnFilters: false, density: "compact" }}
            data={sales?.rows ?? []}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableColumnOrdering={false}
            enableColumnFilterMode={false}
            enableHiding={false}
            enableFilters={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            rowCount={sales?.totalRowCount ?? 0}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isFetching,
              sorting,
            }}
            muiTableHeadCellProps={{
              sx: {
                border: "1px solid #eee",
                width: "max-content",
              },
              "& th:last-child": {
                width: "20px",
                border: "1px solid blue",
              },
            }}
            muiTableProps={{
              sx: {
                border: "1px solid #eee",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                border: "1px solid #F4F4F7",
              },
            }}
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: "error",
                    children: "Erro ao tentar carregar lista.",
                  }
                : undefined
            }
            muiTableBodyRowProps={{ hover: false }}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            muiTablePaginationProps={{ sx: { backgroundColor: "#FFFFFF" } }}
            muiBottomToolbarProps={{ sx: { backgroundColor: "#FFFFFF" } }}
            muiTopToolbarProps={{
              sx: { backgroundColor: "white", marginBottom: "25px" },
            }}
            muiTableHeadRowProps={{
              sx: {
                background: "#fff",
                "& .MuiTableCell-head": {
                  color: "#000",
                  textTransform: "uppercase",
                },
                border: "0.5px solid #eee",
              },
            }}
            enableTableHead={true}
            muiTablePaperProps={{ sx: { boxShadow: "none" } }}
            manualFiltering
            manualPagination
            manualSorting
            localization={MRT_Localization_PT_BR}
            muiTableBodyProps={{
              sx: (theme) => ({
                "& tr": {
                  backgroundColor: "#FFF",
                },
                "& tr > td": {
                  border: "0.5px solid #eee",
                },
              }),
            }}
            /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: expandFilters === true ? "2px" : "0px",
                    }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <IconButton
                        onClick={() => {
                          setExpandFilters((prev) => !prev);
                        }}
                      >
                        <FilterListIcon sx={{ fontSize: 20, color: "#000" }} />
                      </IconButton>
                      <Typography sx={{ color: "#000" }}>
                        Filtrar por
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name="name"
                            defaultValue=""
                            label="Nome"
                            value={customerName}
                            size="small"
                            onChange={(ev) => setCustomerName(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            variant="outlined"
                            type="number"
                            name="contact"
                            fullWidth
                            defaultValue=""
                            label="Telefone"
                            value={contact}
                            size="small"
                            onChange={(ev) => setContact(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Limpar Filtros">
                            <IconButton
                              onClick={() => {
                                clearFilters();
                              }}
                            >
                              <FilterAltOffIcon sx={{ color: "#000" }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default ListSales;
