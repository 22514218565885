import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { Close, Download } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UserDetailsModal = ({ modalDetails, handleCloseDetailsModal }) => {
  return (
    <Drawer
      open={modalDetails}
      keepMounted
      onClose={handleCloseDetailsModal}
      TransitionComponent={Transition}
      anchor={"right"}
      transitionDuration={60}
      style={{height:"400px !important"}}
    >
      <Box sx={{ paddingX: "15px", paddingY: "22px", width: "500px" }}>
        <Box
          sx={{
            paddingY: "22px",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>Danilson Kayumbuca</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "22px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "7px",
                borderColor: "lightgray",
                color: "#E5E7EB",
              }}
            >
              <Download />

              <Typography color="#000"> Export PDF</Typography>
            </Button>
            <IconButton>
              <Close color="red" />
            </IconButton>
          </Box>
        </Box>
        <Divider />

      </Box>
    </Drawer>
  );
};
export default UserDetailsModal;
