import React from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Book, Handshake, Help, LocalLibrary, ManageAccounts, Paid, SupervisedUserCircleSharp } from "@mui/icons-material";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from '@mui/icons-material/Home';

const menus = [
  {
    uri: "/",
    label: "Home",
    type: "nav-item",
    icon: <HomeIcon sx={{ fontSize: 20 }} />,
  },
  /*
  {
    uri: "/dashboard",
    label: "Dashboard",
    type: "nav-item",
    icon: <DashboardIcon sx={{ fontSize: 20 }} />,
  },
  */
  {
    uri: "/plans/particular",
    label: "Planos Particulares",
    type: "nav-item",
    icon: <GroupsIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/wallet", 
    label: "Conta Corrente",
    type: "nav-item",
    icon: <DataUsageIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/guide",
    label: "Manuais",
    type: "nav-item",
    icon: <LocalLibrary sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/contacts",
    label: "Contactos",
    type: "nav-item",
    icon: <CallIcon sx={{ fontSize: 20 }} />,
  },
];

export default menus;
