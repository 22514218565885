import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";


const ContactPage = () => {
  const MediaMobile = useMediaQuery("(max-width:480px)");
  const breadcrumb = (() => {
    const crumbs = {
      links: [
        {
          name: "Dashboard",
          link: "/dashboard",
        },
      ],
      active: {
        name: "Contactos",
      },
    };
    return (
      <Breadcrumbs aria-label="breadcrumb" color={"common.blck"}>
        {crumbs.links.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();
  return (
    <JumboCardQuick
      title={
        <Typography
          variant={"h3"}
          style={{ fontWeight: "bold" }}
          color={"common.black"}
          mb={0}
        >
          Contactos
        </Typography>
      }
      action={MediaMobile ? null : breadcrumb}
      bgColor={"#ffff"}
      boxShadow
      noWrapper
      sx={{
        height: "100%",
        boxShadow: "0",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ paddingLeft: "2rem", paddingRight: "3rem" }}
      >
        <Grid
          item
          marginTop={2}
          xs={12}
          style={{
            display: "flex",
          }}
        >
          <Card sx={{ minWidth: 300 }}>
            <CardContent>
              <Box>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Avatar sx={{ bgcolor: orange[500] }} aria-label="recipe">
                    CM
                  </Avatar>
                  <Typography variant="subtitle1"> Carlos Mergulhão</Typography>
                </Stack>
                <Stack mt={2} direction={"column"} spacing={0.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <WorkIcon />
                    <Typography>Director Comercial</Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CallIcon />
                    <Typography>949 956 295</Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <EmailIcon />
                    <Typography
                      component="a"
                      href="mailto:carlos.mergulhao@medicare.co.ao"
                    >
                      carlos.mergulhao@medicare.co.ao
                    </Typography>
                  </Stack>
                </Stack>
                <Box mt={2} />
                <Divider />
                <Box mt={2} />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    IS
                  </Avatar>
                  <Typography variant="subtitle1">
                    {" "}
                    Isabel dos Santos
                  </Typography>
                </Stack>
                <Stack mt={2} direction={"column"} spacing={0.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <WorkIcon />
                    <Typography>Assistente Comercial</Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CallIcon />
                    <Typography>930 659 711</Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <EmailIcon />
                    <Typography
                      component="a"
                      href="mailto:isabel.santos@medicare.ao"
                    >
                      isabel.santos@medicare.ao
                    </Typography>
                  </Stack>
                </Stack>
                <Box mt={2} />
                <Divider />
                <Box mt={2} />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                    ST
                  </Avatar>
                  <Typography variant="subtitle1">
                    {" "}
                    Suporte Técnico
                  </Typography>
                </Stack>
                <Stack mt={2} direction={"column"} spacing={0.5}>
   
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <EmailIcon />
                    <Typography
                      component="a"
                      href="mailto:suporte@medicare.ao"
                    >
                      suporte@medicare.ao
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
                <Box mt={2} />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                    SAC
                  </Avatar>
                  <Typography variant="subtitle1">
                    {" "}
                    Serviço de Apoio ao Cliente
                  </Typography>
                </Stack>
                <Stack mt={2} direction={"column"} spacing={0.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CallIcon />
                    <Typography>923 167 140</Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <EmailIcon />
                    <Typography
                      component="a"
                      href="mailto:sac@medicare.ao"
                    >
                      sac@medicare.ao
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default ContactPage;
