import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useAuth from "../../../hooks/useAuth";
import { KeyboardArrowDown } from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
import useModal from "app/hooks/useModal";
import PasswordChangeModal from "app/components/PasswordChangeModal";

function stringAvatar(name) {
  if (name) {
    return {
      sx: {
        bgcolor: "#fff2",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
}
const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { user, logout } = useAuth();
  const matches = useMediaQuery("(min-width:517px)");
  const { isModalOpen, openModal, closeModal, modalContent } = useModal();

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              p: (theme) => theme.spacing(2.5),
            }}
          >
            <Avatar
              sizes={"small"}
              {...stringAvatar(user?.name)}
              sx={{
                borderRadius: "10px",
                fontSize: "15px",
                fontWeight: "bold",
                bgcolor: "#ff414d",
                cursor: "pointer",
              }}
            />
            {matches && (
              <>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    height: "70px",
                    p: (theme) => theme.spacing(1.5),
                  }}
                >
                  <Typography
                    sx={{ color: "#fff", margin: "0" }}
                    component="div"
                    variant="h4"
                  >
                    {user?.name} - {user?.entity_id}
                  </Typography>
                  <Typography
                    sx={{ color: "#f5f7fac9" }}
                    variant="subtitle1"
                    component="div"
                  >
                    {user?.parent_name}
                  </Typography>
                </Div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <IconButton style={{ color: "#fff" }}>
                  <KeyboardArrowDown />
                </IconButton>
              </>
            )}
          </Div>
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar alt={user?.name} sx={{ width: 60, height: 60, mb: 2 }} />
          <Typography variant={"h5"}>{user?.name}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {user?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton
              onClick={() => {
                openModal(
                  <PasswordChangeModal onClose={closeModal} maxWidth="sm" />
                );
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Alterar password" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={logout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
        {isModalOpen && modalContent}
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
