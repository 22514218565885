import React, { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import useModal from "app/hooks/useModal";
import PaymentUploadDialog from "./PaymentUploadDialog";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
const PaymentList = () => {
  const { isModalOpen, openModal, closeModal, modalContent } = useModal();
  const [balance, setBalance] = useState(0);
  const { data, refetch, isError,isLoading,isFetching } = useQuery(
    ["pendingPayment-data"],
    async () => {
      const axiosData = await brokersService.getPayments();
      setBalance(axiosData.balance);
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //access nested data with dot notation
        header: "#",
        size: 20,
      },
      {
        accessorKey: "amount",
        header: "Montante",
        size: 20,
      },
      {
        accessorKey: "description", //normal accessorKey
        header: "Descrição",
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: "date_created",
        header: "Data de Criação",
        align: "center",
        size: 20,
      },
      {
        id: "invoice",
        size: 20,
        header: "Anexar Fatura",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row.original.can_upload == 1 || row.original.has_invoice == 0 ? (
              <Tooltip title="Enviar Fatura">
                <IconButton
                  disabled={row.original.need_file == 0 ? true : false}
                  color="primary"
                  onClick={() => {
                    openModal(
                      <PaymentUploadDialog
                        paymentID={row.original.id}
                        onClose={closeModal}
                      />
                    );
                    refetch();
                  }}
                >
                  <AttachFileIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
      },
      {
        id: "receipt",
        size: 20,
        header: "Comprovativo de Pagamento",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row.original.has_receipt == 1 ? (
              <Tooltip title="Download do Comprovativo">
                <IconButton
                  disabled={row.original.need_file == 0 ? true : false}
                  color="primary"
                  onClick={() => {
                    brokersService.downloadFile(
                      row.original.payment_receipt_file,
                      row.original.payment_receipt_mask
                    );
                  }}
                >
                  <DownloadIcon sx={{ color: "#4BB543" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
      },
      {
        id: "payment_receipt_attach_date",
        size: 20,
        header: "Data do Pagamento",
        muiTableHeadCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Saldo: {balance ?? 0}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialReactTable
          data={data ?? []}
          columns={columns}
          initialState={{ showColumnFilters: false, density: "compact" }}
          defaultDisplayColumn={{
            minSize: 5, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 5, //make columns wider by default
          }}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isFetching,
          }}
          default
          positionActionsColumn="last"
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              border: "1px solid #eee",
              width: "max-content",
            },
            "& th:last-child": {
              width: "20px",
              border: "1px solid blue",
            },
            "& tr th:first-child": {
              width: "20px",
            },
          }}
          muiTableProps={{
            sx: {
              border: "1px solid #eee",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              border: "1px solid #eee",
              whiteSpace: "pre-line",
            },
          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "Erro ao tentar carregar lista.",
                }
              : undefined
          }
          localization={MRT_Localization_PT_BR}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr": {
                backgroundColor: "#FFF",
              },
              "& tr > td": {
                border: "0.5px solid #eee",
              },
            }),
          }}
          muiTablePaginationProps={{ sx: { backgroundColor: "#FFFFFF" } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: "#FFFFFF" } }}
          muiTopToolbarProps={{
            sx: { backgroundColor: "white", marginBottom: "25px" },
          }}
          muiTableHeadRowProps={{
            sx: {
              background: "#fff",
              "& .MuiTableCell-head": {
                color: "#000",
              },
              border: "0.5px solid #eee",
            },
          }}
          enableTopToolbar={false}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: "none" } }}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableColumnActions={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          
        />
      </Grid>
      {isModalOpen && modalContent}
    </Grid>
  );
};

export default PaymentList;
