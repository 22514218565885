import React, { useEffect, useState,createContext } from 'react';
import ContentLoader from "@jumbo/components/ContentLoader";
import { useNavigate } from 'react-router-dom';
import authServices from 'app/services/auth-services';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        getMe();
    }, []);

    const getMe=()=>{
        authServices.getCurrentUser().then(({data}) => {
            setUser(data);
            setIsAuthenticated(true);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            if(error.response){
                localStorage.removeItem('token');
                navigate('/login')
                setIsLoaded(true);
            }else{
                navigate('/')
                setIsLoaded(true);
            }

        });
    }


    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        setIsAuthenticated(false);
        queryClient.invalidateQueries();
        navigate('/login')
    }

    const signin = (email, password) => {
        return new Promise((resolve, reject) => {
            authServices.signIn({email:email, password:password})
                .then(({data}) => {
                    resolve(data)
                    localStorage.setItem('token', data.token);
                    getMe();
                    navigate("/",{replace:true});
                })
                .catch((error) => reject(error))
        })
    };

    return (
        <React.Fragment>
            {!isLoaded ? <ContentLoader open={true} /> :
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, logout, signin }}>
                    {children}
                </AuthContext.Provider>}
        </React.Fragment>
    )
};