import styled from "@mui/material/styles/styled";
import { TextField } from "@mui/material";



const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#bf2f38",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#bf2f38",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#bf2f38",
        borderRadius: "6px",
      },
      "&:hover fieldset": {
        borderColor: "#bf2f38",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#bf2f38",
      },
    },
  });

export default CssTextField;
