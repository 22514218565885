import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import React from "react";
import ReactApexChart from "react-apexcharts";

const TreepMap = ({ width, style = null }) => {
  //   const { data } = useQuery(
  //     ["saleMonthVolume"],
  //     async () => {
  //       const axiosData = await brokersService.getDashboardSalesMonthVolumeData();
  //       return axiosData.data;
  //     },
  //     { staleTime: 60000 * 10 }
  //   );

  const data = [
    {
      x: "Kolkata",
      y: 149,
    },
    {
      x: "New Delhi",
      y: 218,
    },
    {
      x: "Mumbai",
      y: 184,
    },
    {
      x: "Ahmedabad",
      y: 55,
    },
    {
      x: "Bangaluru",
      y: 84,
    },
    {
      x: "Pune",
      y: 31,
    },
    {
      x: "Chennai",
      y: 70,
    },
    {
      x: "Jaipur",
      y: 30,
    },
    {
      x: "Surat",
      y: 44,
    },
    {
      x: "Hyderabad",
      y: 68,
    },
    {
      x: "Lucknow",
      y: 28,
    },
    {
      x: "Indore",
      y: 19,
    },
    {
      x: "Kanpur",
      y: 29,
    },
  ];

  const dataStructure = {
    series: [
      {
        data: data.sort((a, b) => a.y - b.y).reverse(),
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
      colors: ["#3B93A5", "#F7B844", "#ADD8C7", "#EC3C65", "#CDD7B6", "#C1F666", "#D43F97", "#1E5D8C", "#421243", "#7F94B0", "#EF6537", "#C0ADDB"],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    },
  };
  return (
    <div id="chart" style={style}>
      <h4>Volume de Vendas Mês Corrente</h4>
      <ReactApexChart options={dataStructure.options} series={dataStructure.series} type="treemap" height={350} width={width} />
    </div>
  );
};

export default TreepMap;
