import brokersService from 'app/services/apis/brokers';
import React, { useState, useEffect } from 'react';

const usePlanCalculation = (extras,planID) => {
  const [isLoading,setIsLoading] = useState(null);
  const [data,setData]=useState({});

  const calculatePlan = async ()=>{
    setIsLoading(true);
    const { data } = await brokersService.calculate(planID, extras);
    setData(data[0]);
    setIsLoading(false);
  } 
  useEffect(() => {
    calculatePlan()
  }, [extras,planID]);

  return {
    isLoading,
    data,
  };
};

export default usePlanCalculation
