import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import brokersService from "app/services/apis/brokers";
import toast from "react-hot-toast";
import SaveIcon from '@mui/icons-material/Save';

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Obrigatório"),
  new_password: yup
    .string()
    .min(6, "Password deve ter no minimo 6 caracteres")
    .required("Password é Obrigatória"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords não correspondem")
    .required("Password de confirmação Obrigatória"),
});

export default function PasswordChangeModal({ onClose }) {
  const [initialValues, setInitialValues] = React.useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  return (
    <Dialog fullWidth={true} maxWidth={"xs"} open={true} onClose={onClose}>
      <DialogTitle>Alterar Password</DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            brokersService
              .changePassword(values)
              .then(() => {
                toast.success("Password alterada com sucesso.");
                onClose()
              })
              .catch(() => toast.error("Erro ao alterar password."));
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off" id="passwordChange-form">
              <Box mt={2} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Palavra Passe"
                    color="primary"
                    fullWidth
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nova Palavra Passe"
                    color="primary"
                    fullWidth
                    type="password"
                    name="new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.new_password && Boolean(errors.new_password)}
                    helperText={touched.new_password && errors.new_password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirme Palavra Passe"
                    color="primary"
                    fullWidth
                    type="password"
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirm_password &&
                      Boolean(errors.confirm_password)
                    }
                    helperText={
                      touched.confirm_password && errors.confirm_password
                    }
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="passwordChange-form"
          disableElevation
          variant="contained"
          size="small"
          sx={{
            borderRadius: "8px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            paddingX: "20px",
            paddingY: "8px",
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
            Gravar
          </Typography>
          <SaveIcon sx={{ paddingBottom: "4px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
