import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Box, Breadcrumbs, Grid, Link, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import BarStackedVertical from "./Charts/BarStackedVertical";
import BarVertical from "./Charts/BarVertical";
import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import CounterCard from "./CounterCard";
import TreepMap from "./Charts/TreepMap";
import BarVerticalMultiple from "./Charts/BarVerticalMultiple";
//import Dayly from "./Overview/Dayly";

const DashBoardPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { data: sales } = useQuery(
    ["saleStats-data"],
    async () => {
      const axiosData = await brokersService.getDashboardSaleStats();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );
  const { data: resultData } = useQuery(
    ["saleOverview-data"],
    async () => {
      const axiosData = await brokersService.getDashboardOverview();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );

  const { data: graphicData } = useQuery(
    ["yearMonthContract"],
    async () => {
      const axiosData = await brokersService.getDashboardYearMonthContractData();
      return axiosData.data;
    },
    { staleTime: 60000 * 10 }
  );

  console.log(resultData);
  const breadcrumb = (() => {
    const crumbs = {
      active: {
        name: "Dashboard",
      },
    };
    return isMobile ? (
      <></>
    ) : (
      <Breadcrumbs aria-label="breadcrumb" color={"common.black"}>
        {crumbs.links?.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();

  return (
    <JumboCardQuick
      title={
        <Typography variant={"h3"} style={{ fontWeight: "bold" }} color={"common.black"} mb={0}>
          Dashboard
        </Typography>
      }
      action={breadcrumb}
      bgColor={"#ffff"}
      noWrapper
      sx={{
        position: "sticky",
        top: "0",
        height: "100%",
        boxShadow: "1",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <Grid container spacing={1} width={isMobile ? "95%" : "80%"} margin={isMobile ? "initial" : "auto"}>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Nº de Vendas Anual acumulado"} total={sales?.sales_count_year} bgColor={"#003E77"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Nº de Vendas do Mês"} total={sales?.sales_count_month} bgColor={"#243F34"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Nº de Vendas da Semana"} total={sales?.sales_count_week} bgColor={"#38B6F1"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Volume de Vendas Anual acumulado"} total={sales?.sales_volume_year} bgColor={"#00001F"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Volume de Vendas do Mês"} total={sales?.sales_volume_month} bgColor={"#E97451"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard title={"Volume de Vendas da Semana"} total={sales?.sales_volume_week} bgColor={"#6C6377"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard secondTitle={"Projeção"} secondResult={resultData?.cards.target.projection} title={"Objectivo"} total={resultData?.cards.target.goal} bgColor={"#6C6377"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard
            secondTitle={"Mês Passado"}
            secondResult={resultData?.cards.actualProduction.last_month.amount}
            title={"Produção Atual"}
            total={resultData?.cards.actualProduction.current_month.amount}
            bgColor={"#6C6377"}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <CounterCard secondTitle={"Produção Média"} secondResult={resultData?.cards.avgSales} title={"Dias Restante"} total={resultData?.cards.daysRemaining} bgColor={"#6C6377"} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={6} display={"grid"} alignItems={"stretch"}>
          <BarStackedVertical
            data={graphicData}
            width={`${100 * 1}%`}
            title={"Número contratos vendidos por mês"}
            style={{
              textAlign: isMobile ? "center" : "left",
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} display={"grid"} alignItems={"stretch"}>
          <BarVertical
            width={"100%"}
            title={"Volume de Vendas Mês Corrente"}
            style={{
              textAlign: isMobile ? "center" : "left",
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          />
        </Grid>
        <Grid item xs={4} display={"grid"} alignItems={"stretch"}>
          <Paper sx={{ p: 1, height: "max-content" }}>
            <Box>
              <Typography variant="caption">{new Date().toDateString()}</Typography>
              <Typography variant="h3" color={"primary"}>
                Vendas Semanais
              </Typography>
              <Box>
                <BarStackedVertical
                  data={[]}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                />
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  31
                </Typography>
                <Typography variant="h1" color={"red"}>
                  12
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <BarStackedVertical
                  data={[]}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                />
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  31
                </Typography>
                <Typography variant="h1" color={"red"}>
                  12
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4} display={"grid"} alignItems={"stretch"}>
          <Paper sx={{ p: 1, height: "max-content" }}>
            <Box>
              <Typography variant="caption">{new Date().toDateString()}</Typography>
              <Typography variant="h3" color={"primary"}>
                Vendas Mensais
              </Typography>
              <Box>
                <BarStackedVertical
                  data={[]}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                />
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  31
                </Typography>
                <Typography variant="h1" color={"red"}>
                  12
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <BarStackedVertical
                  data={[]}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                />
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  31
                </Typography>
                <Typography variant="h1" color={"red"}>
                  12
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4} display={"grid"} alignItems={"stretch"}>
          <Paper sx={{ p: 1, height: "max-content" }}>
            <Box>
              <Typography variant="caption">{new Date().toDateString()}</Typography>
              <Typography variant="h3" color={"primary"}>
                Vendas Diárias
              </Typography>
              <Box>
                {/* <Dayly /> */}
                {/* <BarStackedVertical
                  data={{
                    categories: resultData?.sales_dayly.map((r) => ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"][new Date(r.sale_date).getDay()]),
                    data: [
                      { name: "Não validados", data: resultData?.sales_dayly.map((el) => el.not_validated) },
                      { name: "Validados", data: resultData?.sales_dayly.map((el) => el.validated) },
                    ],
                  }}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                /> */}
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  {resultData?.sales_dayly.map((el) => el.validated).reduce((a, b) => a + b, 0)}
                </Typography>
                <Typography variant="h1" color={"red"}>
                  {resultData?.sales_dayly.map((el) => el.not_validated).reduce((a, b) => a + b, 0)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <BarStackedVertical
                  data={{
                    categories: resultData?.sales_dayly.map((r) => ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"][new Date(r.sale_date).getDay()]),
                    data: [
                      { name: "Pendentes", data: resultData?.sales_dayly.map((el) => el.pending) },
                      { name: "Pagos", data: resultData?.sales_dayly.map((el) => el.payed) },
                    ],
                  }}
                  title={""}
                  width={"100%"}
                  style={{ textAlign: isMobile ? "center" : "left", display: "flex", alignItems: "stretch", flexDirection: "column", justifyContent: "space-between" }}
                />
              </Box>
              <Box sx={{ m: "auto 0 auto auto", width: "max-content" }}>
                <Typography variant="h1" color={"green"}>
                  {resultData?.sales_dayly.map((el) => el.payed).reduce((a, b) => +a + b, 0)}
                </Typography>
                <Typography variant="h1" color={"red"}>
                  {resultData?.sales_dayly.map((el) => el.pending).reduce((a, b) => +a + b, 0)}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} display={"grid"} alignItems={"stretch"}>
          <TreepMap
            width={"100%"}
            style={{
              textAlign: isMobile ? "center" : "left",
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          />
        </Grid>
      </Grid>
      <br />
    </JumboCardQuick>
  );
};

export default DashBoardPage;
