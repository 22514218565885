import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CssTextField from "app/components/CssTextField";
import brokersService from "app/services/apis/brokers";
import React, { useState } from "react";
import { useEffect } from "react";

const PlanStep = (props) => {
  const [activePlan, setActive] = useState(null);

  const { is_submited } = props;

  const { data: plans, isLoading: loadingPlans } = useQuery(
    ["plans-data"],
    async () => {
      const axiosData = await brokersService.getPlans();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );

  useEffect(() => {
    if (props.formik.values.subscription_plan_id !== null) {
      setActive(
        plans?.find(
          (plan) =>
            plan.subscription_plan_id ===
            props.formik.values.subscription_plan_id
        )?.subscription_plan_id
      );
      props.formik.setFieldValue(
        "max_family_members",
        plans?.find(
          (plan) =>
            plan.subscription_plan_id ===
            props.formik.values.subscription_plan_id
        )?.max_family_members
      );
      props.formik.setFieldValue(
        "max_extra_members",
        plans?.find(
          (plan) =>
            plan.subscription_plan_id ===
            props.formik.values.subscription_plan_id
        )?.max_extra_members
      );
    }
  }, [props.formik.values.subscription_plan_id, plans]);

  const MediaMobile = useMediaQuery("(max-width:480px)");

  return (
    <Grid
      container
      spacing={2}
      paddingTop={MediaMobile ? 1 : 6}
      paddingX={MediaMobile ? 0 : 4}
    >
      <Grid item xs={12} lg={5}>
        <CssTextField
          size="small"
          select
          fullWidth
          backgroundColor
          label="Selecione o plano"
          variant="outlined"
          name="subscription_plan_id"
          id="subscription_plan_id"
          InputProps={{ readOnly: is_submited }}
          inputProps={
            loadingPlans
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }
              : null
          }
          value={props.formik.values.subscription_plan_id}
          onChange={(eve) => {
            props.formik.setFieldValue(
              "subscription_plan_id",
              eve.target.value
            );
            props.formik.setFieldValue(
              "max_family_members",
              plans.find(
                (plan) => plan.subscription_plan_id == eve.target.value
              ).max_family_members
            );
            props.formik.setFieldValue(
              "max_extra_members",
              plans.find(
                (plan) => plan.subscription_plan_id == eve.target.value
              ).max_extra_members
            );
            setActive(eve.target.value);
          }}
          error={
            props.formik.touched.subscription_plan_id &&
            Boolean(props.formik.errors.subscription_plan_id)
          }
        >
          {plans?.map((data) => (
            <MenuItem value={data.subscription_plan_id}>{data.name}</MenuItem>
          ))}
        </CssTextField>
        {activePlan == 46 && (
          <Box
            mt={1}
            style={{
              width: "350px",
              height: "470px",
            }}
          >
            <img
              src="/brochura_da_campanha.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "cover",
              }}
            />
          </Box>
        )}
      </Grid>

      <Box paddingX={4} />

      <Grid item xs={12} lg={6} paddingLeft={15}>
          <Typography
            fontSize={16}
          color={"#000"}
          textAlign="center"
        >
          Características do produto
        </Typography>
          <Box
            className="plans-info"
            component={"div"}
            dangerouslySetInnerHTML={{
              __html: plans?.find(
                (plan) => plan.subscription_plan_id === activePlan
              )?.description,
            }}
          />
      </Grid>
    </Grid>
  );
};

export default PlanStep;
