import { convertDateFormat } from "@jumbo/utils";
import brokersAxiosConfig from "../auth/brokerAxiosConfig";

const brokersService = {
  me: async () => {
    const { data } = await brokersAxiosConfig.get("me");
    return data;
  },
  getSales: async (
    pagination,
    sorting,
    customerName = "",
    paymentMethod = 0,
    contact = "",
    isValidated = 0,
    submited = 0,
    isPayed = 0,
    seller = "",
    dateStart = "",
    dateEnd = "",
    document = ""
  ) => {
    const url =
      "sales?" +
      "start=" +
      pagination.pageIndex * pagination.pageSize +
      "&limit=" +
      pagination.pageSize +
      "&sorting=" +
      (sorting ? JSON.stringify(sorting) : JSON.stringify([])) +
      "&name=" +
      customerName +
      "&contact=" +
      contact +
      "&payment_method=" +
      paymentMethod +
      "&is_submited=" +
      submited +
      "&seller=" +
      seller +
      "&document=" +
      document +
      "&is_payed=" +
      isPayed +
      "&is_validated=" +
      isValidated +
      "&date_start=" +
      convertDateFormat(dateStart) +
      "&date_end=" +
      convertDateFormat(dateEnd);
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  getCurrentAccount: async (
    pagination,
    sorting,
    startDate = "",
    endDate = ""
  ) => {
    const url =
      "current-accounts?" +
      "start=" +
      pagination.pageIndex * pagination.pageSize +
      "&limit=" +
      pagination.pageSize +
      "&sorting=" +
      (sorting ? JSON.stringify(sorting) : JSON.stringify([]));
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  validateBi: async (bi) => {
      const { data } = await brokersAxiosConfig.get("validate/bi/" + bi);
      return data;

  },
  countries: async () => {
    try {
      const { data } = await brokersAxiosConfig.get("countries/");
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  provinces: async (countryID) => {
    try {
      const { data } = await brokersAxiosConfig.get(
        "countries/" + countryID + "/zones"
      );
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  municipes: async (provinceID) => {
    try {
      const { data } = await brokersAxiosConfig.get(
        "zones/" + provinceID + "/counties"
      );
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },

  paymentMethods: async () => {
    const { data } = await brokersAxiosConfig.get("/payment-methods");
    return data;
  },
  getpaymentMethods: async (subscription_plan_id) => {
    const { data } = await brokersAxiosConfig.get(
      "payment-methods?subscription_plan=" + subscription_plan_id
    );
    return data;
  },

  getPlans: async () => {
    const { data } = await brokersAxiosConfig.get("/plans");
    return data;
  },

  banks: async () => {
    try {
      const { data } = await brokersAxiosConfig.get("/banks");
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },

  calculate: async (plan_id, extra_number) => {
    const url = "&plan_id=" + plan_id + "&extra_number=" + extra_number;
    const { data } = await brokersAxiosConfig.get("/sales/calculate?" + url);
    return data;
  },

  downloadFile: async (filename) => {
    const payload = { filename: filename };
    const data = await brokersAxiosConfig.post("sales/file/download/", payload);
    return data;
  }, // unused

  uploadFiles: async (payload) => {
    try {
      const { data } = await brokersAxiosConfig.post("/upload/file", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  createSales: async (payload) => {
    try {
      const { data } = await brokersAxiosConfig.post("/sales/submit/", payload);
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },

  save: async (payload) => {
    try {
      const { data } = await brokersAxiosConfig.post("/sales/save", payload);
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  getSaleSaved: async (sale_id) => {
    try {
      const { data } = await brokersAxiosConfig.get("/sales/" + sale_id);
      if (data) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  getCurrentAccount: async (
    pagination,
    sorting,
    dateStart = "",
    dateEnd = ""
  ) => {
    const url =
      "current-accounts?" +
      "start=" +
      pagination.pageIndex * pagination.pageSize +
      "&limit=" +
      pagination.pageSize +
      "&sorting=" +
      (sorting ? JSON.stringify(sorting) : JSON.stringify([])) +
      "&start_date=" +
      convertDateFormat(dateStart) +
      "&end_date=" +
      convertDateFormat(dateEnd);
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },

  sendReferenceMessage: async (payload) => {
      const { data } = await brokersAxiosConfig.post("/banks/send-sms", payload);
      return data;
  },

  // Dashboard Brokers API

  getDashboardSalesMonthVolumeData: async () => {
    const url = "dashboard/salesMonthVolume";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  getDashboardYearMonthVolumeData: async () => {
    const url = "dashboard/yearMonthVolume";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  getDashboardSalesMonthContractData: async () => {
    const url = "dashboard/salesMonthContract";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  getDashboardYearMonthContractData: async () => {
    const url = "dashboard/yearMonthContract";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },

  getDashboardSaleStats: async () => {
    const url = "dashboard/salesStats";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  getDashboardOverview: async () => {
    const url = "dashboard/overview";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  downloadPropostaAdesao: async (payload)=>{
    brokersAxiosConfig.post(
      '/downloadPropostaAdesao',payload,{
        responseType:'blob'
      }
    ).then((response)=>{
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'PropostaAdesao_'+payload.saleID+'.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
  },
  downloadFile: async (filename,filemask)=>{
    brokersAxiosConfig.post(
      '/download',{filename:filename,filemask:filemask},{
        responseType:'blob'
      }
    ).then((response)=>{
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filemask);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
  },
  changePassword: async (payload) => {
    const data = await brokersAxiosConfig.post("password-change", payload);
    return data;
  }, 
  getPayments: async () => {
    const url = "payments";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  invoiceSubmit: async (payload) => {
    const data = await brokersAxiosConfig.post("payments/invoice", payload);
    return data;
  }, 
  getGuides: async () => {
    const url = "guides";
    const { data } = await brokersAxiosConfig.get(url);
    return data;
  },
  exportSalesCSV: async (
    pagination,
    sorting,
    customerName = "",
    paymentMethod = 0,
    contact = "",
    isValidated = 0,
    submited = 0,
    isPayed = 0,
    seller = "",
    dateStart = "",
    dateEnd = "",
    document = ""
  ) => {
    const url =
      "sales/exportCSV?" +
      "start=" +
      pagination.pageIndex * pagination.pageSize +
      "&limit=" +
      pagination.pageSize +
      "&sorting=" +
      (sorting ? JSON.stringify(sorting) : JSON.stringify([])) +
      "&name=" +
      customerName +
      "&contact=" +
      contact +
      "&payment_method=" +
      paymentMethod +
      "&is_submited=" +
      submited +
      "&seller=" +
      seller +
      "&document=" +
      document +
      "&is_payed=" +
      isPayed +
      "&is_validated=" +
      isValidated +
      "&date_start=" +
      convertDateFormat(dateStart) +
      "&date_end=" +
      convertDateFormat(dateEnd);
    const { data } = await brokersAxiosConfig.get(url,{ responseType: 'blob' });
    if(data){
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = window.document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sales_'+Date.now()+'.csv'); //or any other extension
      window.document.body.appendChild(link);
      link.click();
      link.remove();
    };
  },
};
export default brokersService;
