import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Download, Drafts, Inbox } from "@mui/icons-material";
import { Box, Breadcrumbs, CardContent, Divider, Grid, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import React from "react";
import { useNavigate } from "react-router-dom";

const Guide = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { data, isError } = useQuery(
    ["guides-data"],
    async () => {
      const axiosData = await brokersService.getGuides();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );
  const navigate = useNavigate();
  const breadcrumb = (() => {
    const crumbs = {
      links: [
        {
          name: "Página Inicial",
          link: "/",
        },
      ],
      active: {
        name: "Manuais",
      },
    };
    return isMobile ? (
      <></>
    ) : (
      <Breadcrumbs aria-label="breadcrumb" color={"common.black"}>
        {crumbs.links?.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();

  return (
    <JumboCardQuick
      title={
        <Typography variant={"h3"} style={{ fontWeight: "bold" }} color={"common.black"} mb={0}>
          Manuais
        </Typography>
      }
      action={breadcrumb}
      bgColor={"#ffff"}
      noWrapper
      sx={{
        position: "sticky",
        top: "0",
        height: "100%",
        boxShadow: "1",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <JumboCardQuick sx={{ p: 2, boxShadow: 0 }}>
        <Grid container spacing={4}>
          {data?.map((e) => (
            <Grid item xs={3}>
              <Typography variant="h3" color={"#bf2f38"}>
                {e.name}
              </Typography>
              <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <List component="nav" aria-label="main mailbox folders">
                  {e.guides?.map((link) => (
                    <Link href={link.url} color={"#777"} underline="none" target="_blank">
                      <ListItemButton>
                        <ListItemIcon>
                          <Download />
                        </ListItemIcon>
                        <ListItemText primary={link.title} />
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              </Box>
            </Grid>
          ))}
          {data?.length==0 && <Grid item xs={12}><Typography align="center">Sem Manuais disponiveis.</Typography></Grid>}
        </Grid>
      </JumboCardQuick>
    </JumboCardQuick>
  );
};

export default Guide;
