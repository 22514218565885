import React, { useEffect, useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ListWallet from "./ListWallet";
import PaymentList from "./Payment/PaymentList";
import { useNavigate } from "react-router-dom";
import useAuth from "app/hooks/useAuth";

const WalletPage = () => {
  const {user} = useAuth()
  const navigate = useNavigate()
  useEffect(()=>{
    if(user?.entity_type_id != 11){
        navigate('/');
    }
  },[])
  const isMobile = useMediaQuery("(max-width: 768px)");
  const breadcrumb = (() => {
    const crumbs = {
      links: [
        {
          name: "Página Inicial",
          link: "/",
        },
      ],
      active: {
        name: "Conta Corrente",
      },
    };
    return isMobile ? (
      <></>
    ) : (
      <Breadcrumbs aria-label="breadcrumb" color={"common.black"}>
        {crumbs.links.map((e) => (
          <Link underline="hover" color="#777" href={e.link}>
            {e.name}
          </Link>
        ))}
        <Typography color="inherit">{crumbs.active.name}</Typography>
      </Breadcrumbs>
    );
  })();

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <JumboCardQuick
      title={
        <Typography
          variant={"h3"}
          style={{ fontWeight: "bold" }}
          color={"common.black"}
          mb={0}
        >
          Conta Corrente
        </Typography>
      }
      action={breadcrumb}
      bgColor={"#ffff"}
      noWrapper
      sx={{
        height: "100%",
        boxShadow: "0",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <JumboCardQuick sx={{ p: 2, boxShadow: 0 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Lista" value="1" />
              <Tab label="Faturação" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListWallet />
          </TabPanel>
          <TabPanel value="2">
            <PaymentList />
          </TabPanel>
        </TabContext>
      </JumboCardQuick>
    </JumboCardQuick>
  );
};

export default WalletPage;
