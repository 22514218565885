import React, { useState } from "react";
import { Avatar, Box, Card, CardContent, InputAdornment, Typography } from "@mui/material";
// import {auth} from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
// import {useAuthSignInWithEmailAndPassword} from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ASSET_IMAGES, ASSET_LOGOS } from "../../utils/constants/paths";
import useAuth from "app/hooks/useAuth";
import Image from "./imagem.png";
import { ContactlessOutlined, Https, Person, PersonPinCircle } from "@mui/icons-material";
import styled from "@emotion/styled";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  email: yup.string("Digite seu e-mail").email("Entre com um email válido").required("O e-mail é obrigatório"),
  password: yup.string("Digite sua senha").required("A Senha é obrigatória"),
});
const styles = {
  paperContainer: {
    height: "100vh",
    background: `url(${Image}) center/cover no-repeat`,
  },
  input: {
    "input-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      color: "red",
    },

    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
  },
};
const options = {
  shouldForwardProp: (prop) => prop !== "borderColor",
};
const outlinedSelectors = ["& .MuiOutlinedInput-notchedOutline", "&:hover .MuiOutlinedInput-notchedOutline", "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"];
const TextField = styled(
  JumboTextField,
  options
)(({ borderColor = "#fff" }) => ({
  "& input": {
    border: 0,
    color: "#fff", // Change the text color on hover
  },
  "& label.Mui-focused": {
    color: borderColor,
    border: "0",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#fff",
    opacity: 1,
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderColor: "#fff", // Change the border color on hover
  },
  ".MuiFormHelperText-contained": {
    color: "#fff", // Change the border color on hover
  },
  [outlinedSelectors.join(",")]: {
    borderWidth: 1,
    borderColor,
  },
}));
const Login1 = () => {
  const { signin } = useAuth();
  const [isLoading, setLoading] = useState(false);

  //setAuthToken
  //temp
  const mutation = { isError: false };
  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        background: styles.paperContainer.background,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4, bgcolor: "transparent", boxShadow: "0" }}>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              setLoading(true);
              signin(data.email, data.password)
                .then((dt) => {
                  console.log("2", dt);
                  setLoading(false);
                  console.log("dentro do then");
                })
                .catch((err) => {
                  // console.log(err.response.status);
                  setLoading(false);
                  // console.log(err.response.data.message);
                  toast.error("Credênciais inválidos");
                });
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Box display={"flex"} justifyContent={"center"}>
                  <Avatar sx={{ bgcolor: "transparent", scale: "4.5", margin: "1rem auto 0" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="235" height="65" viewBox="0 0 235 65" fill="none">
                      <path
                        d="M32.3341 5.88672C17.7424 5.88672 5.88672 17.7424 5.88672 32.3341C5.88672 46.9257 17.7424 58.7815 32.3341 58.7815C46.9257 58.7815 58.7815 46.9257 58.7815 32.3341C58.74 17.701 46.9257 5.88672 32.3341 5.88672ZM41.1222 36.7282H36.7282V41.1222C36.7282 43.568 34.7384 45.5163 32.3341 45.5163C29.8883 45.5163 27.94 43.5265 27.94 41.1222V36.7282H23.5045C21.0587 36.7282 19.1104 34.7384 19.1104 32.3341C19.1104 29.8883 21.1002 27.94 23.5045 27.94H27.8986V23.5045C27.8986 21.0587 29.8883 19.1104 32.2926 19.1104C34.7384 19.1104 36.6867 21.1002 36.6867 23.5045V27.8986H41.0808C43.5265 27.8986 45.4749 29.8883 45.4749 32.2926C45.5163 34.7384 43.568 36.7282 41.1222 36.7282Z"
                        fill="white"
                      />
                      <path d="M222.108 44.0652H70.5125V45.143H222.108V44.0652Z" fill="white" />
                      <path
                        d="M225.797 12.1872C224.181 12.1872 222.896 13.5137 222.896 15.1304C222.896 16.7471 224.181 18.0736 225.797 18.0736C227.414 18.0736 228.699 16.7471 228.699 15.1304C228.699 13.5137 227.373 12.1872 225.797 12.1872ZM225.797 11.7312C227.621 11.7312 229.155 13.265 229.155 15.1304C229.155 16.9958 227.663 18.5296 225.797 18.5296C223.932 18.5296 222.44 16.9958 222.44 15.1304C222.44 13.265 223.932 11.7312 225.797 11.7312ZM226.751 17.1202L225.715 15.462C225.59 15.462 225.424 15.462 225.176 15.4206V17.1202H224.678V13.2235L225.093 13.1821C225.341 13.1821 225.507 13.1821 225.632 13.1821C226.544 13.1821 227 13.5552 227 14.3013C227 14.55 226.917 14.7573 226.792 14.9646C226.627 15.1719 226.461 15.2962 226.253 15.3377L227.373 17.1202H226.751ZM225.176 13.6795V14.9646C225.3 14.9646 225.424 15.006 225.549 15.006C225.88 15.006 226.088 14.9646 226.212 14.8402C226.336 14.7573 226.419 14.55 226.419 14.3013C226.419 14.0941 226.336 13.9282 226.171 13.8453C226.005 13.7624 225.756 13.721 225.466 13.721C225.424 13.6795 225.341 13.6795 225.176 13.6795Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M94.2238 41.1217L92.3584 24.0014L84.275 41.1217H82.8656L74.0774 24.0014L72.5437 41.1217H70.5125L72.8753 18.5295H74.7407L84.0677 36.9764L92.8559 18.5295H94.7627L97.3329 41.1217H94.2238Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M120.63 39.2978C121.086 39.3807 121.625 39.4221 122.246 39.4636C122.827 39.505 123.49 39.5465 124.278 39.5465C125.77 39.5465 127.096 39.4636 128.299 39.2149C129.501 38.9661 130.454 38.5931 131.2 38.0956C131.573 37.8469 131.947 37.5153 132.278 37.1007C132.651 36.6862 132.941 36.1059 133.232 35.4011C133.522 34.6964 133.729 33.8674 133.895 32.8725C134.061 31.8776 134.144 30.6754 134.144 29.3075C134.144 27.6079 133.895 26.157 133.314 24.9548C132.776 23.7941 132.071 22.8407 131.159 22.0945C130.288 21.3898 129.252 20.8509 128.091 20.5608C126.931 20.2291 125.728 20.1048 124.485 20.1048C123.946 20.1048 123.49 20.1048 123.158 20.1048C122.785 20.1048 122.495 20.1462 122.205 20.1462C121.915 20.1877 121.666 20.1877 121.417 20.2291C121.169 20.2706 120.92 20.2706 120.63 20.312V39.2978ZM117.438 18.9441C117.935 18.9026 118.516 18.8197 119.179 18.7783C119.842 18.7368 120.547 18.6954 121.251 18.6539C121.956 18.6124 122.702 18.571 123.449 18.571C124.195 18.5295 124.899 18.5295 125.604 18.5295C127.387 18.5295 129.045 18.7368 130.496 19.1513C131.946 19.5659 133.232 20.2291 134.268 21.0997C135.304 21.9702 136.133 23.048 136.672 24.3745C137.253 25.701 137.543 27.2348 137.543 28.9758C137.543 30.7583 137.294 32.375 136.755 33.8674C136.216 35.3597 135.429 36.6447 134.392 37.7225C133.356 38.8003 132.029 39.6294 130.496 40.2098C128.962 40.8316 127.138 41.1217 125.065 41.1217C123.697 41.1217 122.329 41.0803 121.003 40.9974C119.635 40.9145 118.474 40.8316 117.438 40.7487V18.9441Z"
                        fill="white"
                      />
                      <path d="M145.377 18.5295H142.02V41.1217H145.377V18.5295Z" fill="white" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M158.891 41.1215C157.233 41.1215 155.824 40.8313 154.663 40.2924C153.502 39.7535 152.59 38.9659 151.844 37.971C151.139 36.9761 150.601 35.774 150.269 34.406C149.937 32.9966 149.771 31.4628 149.771 29.7217C149.771 27.6076 150.103 25.8665 150.725 24.4571C151.347 23.0477 152.176 21.887 153.171 20.975C154.166 20.1045 155.326 19.4412 156.57 19.0681C157.813 18.6951 159.057 18.4878 160.342 18.4878C160.798 18.4878 161.337 18.4878 161.876 18.5292C162.456 18.5707 162.995 18.6122 163.534 18.6536C164.073 18.6951 164.57 18.7365 165.026 18.778C165.482 18.8194 165.855 18.8609 166.146 18.9438L165.317 21.5554C165.026 21.4725 164.612 21.3895 164.073 21.2652C163.534 21.1408 162.954 21.0579 162.332 20.975C161.71 20.8921 161.088 20.8092 160.508 20.7263C159.928 20.6434 159.389 20.6434 159.016 20.6434C158.104 20.6434 157.357 20.7263 156.694 20.8506C156.031 20.975 155.451 21.2652 154.912 21.6797C154.248 22.1772 153.751 23.1306 153.502 24.54C153.254 25.9494 153.088 27.649 153.088 29.7632C153.088 32.9551 153.585 35.3594 154.539 36.9761C155.492 38.6343 157.067 39.4633 159.181 39.4633C159.762 39.4633 160.384 39.4219 161.047 39.3804C161.669 39.339 162.332 39.2561 162.954 39.1732C163.576 39.0902 164.197 38.9659 164.819 38.8415C165.441 38.7172 165.98 38.5513 166.519 38.3855V39.5462C165.98 39.795 165.358 40.0437 164.736 40.2095C164.114 40.4168 163.451 40.5826 162.788 40.7069C162.125 40.8313 161.461 40.9557 160.798 41.0386C160.176 41.08 159.513 41.1215 158.891 41.1215Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M183.681 41.1217L180.986 33.0383L172.695 33.1626L169.835 41.1217H167.679L176.177 19.2757L178.706 18.5295L186.665 41.1217H183.681ZM180.53 31.6289L176.965 21.3484L173.234 31.6289H180.53Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M196.076 31.546H193.008V40.9145H190.065V18.7783C190.977 18.6954 191.93 18.6539 193.008 18.6124C194.086 18.571 195.122 18.5295 196.2 18.5295C197.444 18.5295 198.563 18.6539 199.516 18.9441C200.47 19.2343 201.216 19.6073 201.838 20.1462C202.46 20.6437 202.916 21.3069 203.206 22.0531C203.496 22.7993 203.662 23.6698 203.662 24.6232C203.662 25.5352 203.537 26.3643 203.289 27.069C203.04 27.7737 202.708 28.3955 202.294 28.9344C201.879 29.4733 201.382 29.9293 200.843 30.3024C200.262 30.6754 199.682 31.0071 199.102 31.2143C199.765 32.375 200.387 33.4528 200.967 34.4892C201.589 35.5255 202.169 36.4375 202.791 37.2251C203.413 38.0127 204.035 38.6345 204.657 39.0905C205.278 39.5465 205.942 39.7952 206.646 39.7952H206.854V40.7901C206.563 40.9145 206.149 40.9974 205.651 41.0803C205.154 41.1632 204.657 41.2046 204.201 41.2046C203.496 41.2046 202.874 41.1217 202.377 40.9145C201.879 40.7072 201.382 40.3341 200.926 39.7952C200.47 39.2563 199.931 38.5102 199.392 37.5567C198.853 36.6033 198.148 35.3597 197.361 33.8674L196.076 31.546ZM193.92 29.9707C195.247 29.9707 196.324 29.8878 197.153 29.722C197.983 29.5562 198.604 29.3489 199.06 29.0587C199.268 28.9344 199.433 28.7686 199.599 28.5199C199.765 28.3126 199.931 28.0224 200.055 27.6908C200.18 27.3592 200.262 26.9446 200.345 26.4472C200.428 25.9497 200.428 25.3694 200.428 24.7061C200.428 23.8356 200.304 23.1309 200.055 22.5505C199.807 21.9702 199.475 21.4727 199.019 21.0997C198.563 20.7266 198.024 20.4364 197.361 20.2706C196.698 20.1048 195.993 20.0219 195.164 20.0219C194.749 20.0219 194.418 20.0219 194.086 20.0633C193.796 20.1048 193.423 20.1048 192.967 20.1462V29.9707H193.92Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M222.233 41.1217H210.128V18.5295H221.694L221.486 20.1877L213.237 20.2706V28.2711L221.03 28.3955L220.823 29.8464L213.237 30.0122V39.3807L222.44 39.5465L222.233 41.1217Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M113.873 41.1217H101.769V18.5295H113.334L113.127 20.1877L104.836 20.2706V28.2711L112.671 28.3955L112.464 29.8464L104.836 30.0122V39.3807L114.08 39.5465L113.873 41.1217Z"
                        fill="white"
                      />
                      <path
                        d="M167.057 58.6151L165.648 54.4698L161.295 54.5112L159.762 58.6151H158.642L163.119 47.4226L164.446 47.0496L168.633 58.6566H167.057V58.6151ZM165.399 53.7236L163.534 48.459L161.586 53.7236H165.399Z"
                        fill="white"
                      />
                      <path
                        d="M179.037 58.6562L171.078 49.8266H171.037C171.037 50.6557 171.078 51.4018 171.078 52.1066C171.078 52.3967 171.078 52.6869 171.078 52.9771C171.078 53.2673 171.078 53.516 171.078 53.7647C171.078 54.0134 171.078 54.2207 171.078 54.3865C171.078 54.5523 171.078 54.6767 171.078 54.7596V58.5733H170.083V47.1736H170.747L178.747 55.9617C178.747 55.1741 178.706 54.4694 178.706 53.8062C178.706 53.516 178.706 53.2258 178.706 52.9356C178.706 52.6455 178.706 52.3967 178.706 52.1066C178.706 51.8164 178.706 51.6091 178.706 51.4433C178.706 51.2775 178.706 51.0702 178.706 50.9873V47.2565H179.701V58.6148H179.037V58.6562Z"
                        fill="white"
                      />
                      <path
                        d="M189.691 53.8891L187.162 53.7647L187.287 53.1844H191.183V57.7857C190.935 57.9101 190.645 57.993 190.313 58.1173C189.981 58.2417 189.608 58.3246 189.194 58.4075C188.779 58.4904 188.365 58.5733 187.95 58.6148C187.536 58.6562 187.08 58.6977 186.706 58.6977C185.877 58.6977 185.214 58.5733 184.634 58.2832C184.053 57.993 183.597 57.5784 183.266 57.081C182.893 56.5421 182.644 55.9617 182.478 55.2156C182.312 54.5109 182.229 53.6818 182.229 52.8113C182.229 51.9407 182.354 51.1531 182.561 50.4484C182.768 49.7437 183.141 49.1634 183.556 48.6659C184.012 48.1685 184.592 47.7954 185.256 47.5467C185.96 47.2979 186.748 47.1736 187.66 47.1736C187.909 47.1736 188.157 47.1736 188.406 47.215C188.696 47.215 188.986 47.2565 189.235 47.2979C189.525 47.3394 189.774 47.3809 190.023 47.3809C190.271 47.4223 190.479 47.4638 190.645 47.4638L190.23 48.7903C190.023 48.7488 189.815 48.6659 189.525 48.6245C189.235 48.5416 188.945 48.5001 188.613 48.4586C188.282 48.4172 187.992 48.3757 187.66 48.3343C187.328 48.2928 187.038 48.2928 186.789 48.2928C186.375 48.2928 186.002 48.3343 185.629 48.3757C185.256 48.4172 184.924 48.583 184.634 48.8317C184.468 48.9561 184.344 49.1634 184.219 49.4535C184.136 49.7437 184.053 50.0753 183.971 50.4484C183.929 50.8215 183.888 51.1946 183.846 51.6506C183.805 52.0651 183.805 52.4796 183.805 52.8942C183.805 53.6403 183.846 54.3036 183.971 54.9254C184.053 55.5472 184.219 56.0861 184.468 56.5006C184.717 56.9566 185.007 57.2883 185.339 57.537C185.712 57.7857 186.168 57.9101 186.665 57.9101C186.914 57.9101 187.162 57.9101 187.453 57.8686C187.743 57.8272 187.992 57.8272 188.282 57.7857C188.53 57.7442 188.821 57.7028 189.028 57.7028C189.277 57.6613 189.484 57.6199 189.65 57.5784V53.8891H189.691Z"
                        fill="white"
                      />
                      <path
                        d="M204.2 52.8941C204.2 53.8061 204.075 54.5937 203.868 55.3398C203.661 56.086 203.288 56.6664 202.832 57.1638C202.376 57.6612 201.795 58.0758 201.132 58.3245C200.428 58.6147 199.64 58.739 198.728 58.739C197.816 58.739 196.987 58.6147 196.324 58.3245C195.66 58.0343 195.121 57.6612 194.665 57.1223C194.209 56.5834 193.919 56.0031 193.712 55.2984C193.505 54.5937 193.422 53.7646 193.422 52.8941C193.422 52.0236 193.505 51.2359 193.712 50.4898C193.919 49.7851 194.251 49.1633 194.665 48.6658C195.08 48.1684 195.66 47.7953 196.324 47.5051C196.987 47.2564 197.774 47.0906 198.686 47.0906C199.598 47.0906 200.386 47.2149 201.091 47.5051C201.795 47.7538 202.334 48.1684 202.79 48.6658C203.246 49.1633 203.578 49.7851 203.827 50.4898C204.117 51.2359 204.2 52.0236 204.2 52.8941ZM202.583 52.8941C202.583 52.1894 202.5 51.5261 202.376 50.9043C202.251 50.2825 202.003 49.7851 201.671 49.3705C201.34 48.956 200.966 48.6244 200.469 48.3756C199.972 48.1269 199.391 48.0026 198.728 48.0026C198.148 48.0026 197.609 48.0855 197.111 48.2098C196.614 48.3342 196.199 48.5415 195.909 48.7902C195.743 48.9145 195.619 49.1218 195.495 49.412C195.37 49.7022 195.287 49.9923 195.246 50.3654C195.163 50.7385 195.121 51.1116 195.121 51.5676C195.08 51.9821 195.08 52.4381 195.08 52.8941C195.08 53.5988 195.121 54.262 195.246 54.8838C195.37 55.5057 195.577 56.0031 195.868 56.4591C196.158 56.9151 196.531 57.2467 196.987 57.4954C197.443 57.7441 198.023 57.8685 198.728 57.8685C199.391 57.8685 199.972 57.8271 200.428 57.7027C200.884 57.5783 201.298 57.3711 201.671 57.0394C201.837 56.9151 201.961 56.7078 202.086 56.4176C202.21 56.1275 202.293 55.7958 202.376 55.4227C202.459 55.0497 202.5 54.6351 202.542 54.2206C202.542 53.7646 202.583 53.3501 202.583 52.8941Z"
                        fill="white"
                      />
                      <path d="M206.687 58.6149V47.2981H208.221V57.7444H212.532L212.408 58.6149H206.687Z" fill="white" />
                      <path
                        d="M221.693 58.6151L220.284 54.4698L215.931 54.5112L214.397 58.6151H213.278L217.755 47.4226L219.082 47.0496L223.268 58.6566H221.693V58.6151ZM220.035 53.7236L218.17 48.459L216.221 53.7236H220.035Z"
                        fill="white"
                      />
                    </svg>
                  </Avatar>
                </Box>
                <br />
                <br />
                <br />
                <Div sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person style={{ color: "#fff" }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    placeholder=" Digite seu email"
                    name="email"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Https style={{ color: "#fff" }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    placeholder=" Digite sua senha"
                    name="password"
                    type="password"
                  />
                </Div>
                <Typography textAlign={"right"} variant={"body1"}>
                  {/*                                     <Link underline="none" href="#">Forgot your password?</Link>
                   */}{" "}
                </Typography>
                <Div sx={{ mb: 1 }}>
                  {/*                                     <FormControlLabel control={<Checkbox />} label="Remember me" />
                   */}{" "}
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  style={{
                    background: "#fff",
                    border: "none",
                    fontWeight: "bold",
                    color: "#000",
                    height: "45px",
                  }}
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isLoading}
                >
                  {!isLoading && "Entrar"}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login1;
