import PrivateRoute from "app/routes/middleware/PrivateRoute";
/* import Page404 from "../ErrorPapes/Page404"; */
import Page from "@jumbo/shared/Page";
/* import Home from "../../pages/home"; */
import ListUsers from "./Pages/ListUsers";
import DashBoardPage from "../DashboardPage";

const usersRoutes = [
  {
    path: "/users",
    element: (
      <PrivateRoute>
        <Page component={ListUsers} />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Page component={DashBoardPage} />
      </PrivateRoute>
    ),
  },
];

export default usersRoutes;
