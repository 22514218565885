import ListSales from "./Pages/ListSales";

const salesRoute = [
  {
    path: "/sales",
    element: <ListSales />,
  },
];

export default salesRoute;
