import { IconButton } from "@mui/material";
import styled from "@mui/material/styles/styled";



const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "#b0b0b0",
    boxShadow: "none",
  },
});
export default StyledIconButton;
