import jwtAuthAxios from "./auth/brokerAxiosConfig";

const authServices = {};

authServices.getCurrentUser = async () => {
    const {data} = await jwtAuthAxios.get("/me");
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const {data} = await jwtAuthAxios.post('/login', loginCreds);
    return data;
};

export default authServices;