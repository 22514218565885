import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import { ChevronRight, Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiContainedInput-root {
    border: 1px solid #bf2f38; /* Set border color: #bf2f38 */
  }
`;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#bf2f38",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#bf2f38",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#bf2f38",
      borderRadius: "6px",
    },
    "&:hover fieldset": {
      borderColor: "#bf2f38",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#bf2f38",
    },
  },
});

const AddUserModal = ({ open, handleCloseModal }) => {
  const [initialValues, setInitialValues] = useState({
    name: "DANILSON KAYUMBUCA",
    email: "danilson.kayumbuca@medicare.ao",
    phone_number: "932129667",
    position: "1",
    user_group: "1",
    password: "124578",
    confirm_password: "124578",
    account_status: true,
  });

  const Alert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Novo usuário criado com sucesso!",
      showConfirmButton: false,
      timer: 3500,
      width: "600px",
      customClass: {
        heightAuto: false, // Disable auto height
        popup: "custom-popup-class", // Add a custom class for styling
      },
      willOpen: (toast) => {
        toast.style.height = "400px"; // Set the height to 500px
      },
    });
    handleCloseModal();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      TransitionComponent={Transition}
      maxWidth={"lg"}
    >
      <Formik
        initialValues={initialValues}
        /* validationSchema={} */
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <Form autoComplete="off" noValidate id="add-User">
            <DialogTitle>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Novo usuário</Typography>
                <IconButton onClick={handleCloseModal}>
                  <Close color="primary" />
                </IconButton>
              </Box>
            </DialogTitle>
            <Box width="100%" sx={{ paddingX: "25px" }}>
              <Divider />
            </Box>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={12} spacing={2}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Nome
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="name"
                    id="name"
                    placeholder="Nome"
                    size="small"
                    value={values.name}
                    color="primary"
                    sx={{ borderColor: "red" }}
                    onChange={(eve) => setFieldValue("name", eve.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Telefone
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="phone_number"
                    id="phone_number"
                    placeholder="Telefone"
                    value={values.phone_number}
                    size="small"
                    onChange={(eve) =>
                      setFieldValue("phone_number", eve.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Email
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={values.email}
                    size="small"
                    onChange={(eve) => setFieldValue("email", eve.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Cargo
                  </Typography>
                  <CssTextField
                    fullWidth
                    select
                    name="position"
                    id="position"
                    value={values.position}
                    size="small"
                    onChange={(eve) =>
                      setFieldValue("position", eve.target.value)
                    }
                  >
                    <MenuItem value={1}>Cargo 1</MenuItem>
                  </CssTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Grupo
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="user_group"
                    select
                    id="user_group"
                    value={values.user_group}
                    size="small"
                    onChange={(eve) =>
                      setFieldValue("user_group", eve.target.value)
                    }
                  >
                    <MenuItem value={1} sx={{ paddingBottom: "8px" }}>
                      Tipo 1
                    </MenuItem>
                  </CssTextField>
                </Grid>{" "}
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Senha
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="password"
                    placeholder="Senha"
                    id="password"
                    size="small"
                    type="password"
                    value={values.password}
                    onChange={(eve) =>
                      setFieldValue("password", eve.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Confirmar senha
                  </Typography>
                  <CssTextField
                    fullWidth
                    type="password"
                    name="confirm_password"
                    id="user_group"
                    placeholder="Confirmar senha"
                    value={values.confirm_password}
                    size="small"
                    onChange={(eve) =>
                      setFieldValue("user_group", eve.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value={values.account_status}
                    onChange={(ev) =>
                      setFieldValue("account_status", ev.target.value)
                    }
                    control={<Switch />}
                    label="Estado da conta"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Box width="100%" sx={{ paddingX: "25px", paddingY: "5px" }}>
              <Divider />
            </Box>
            <DialogActions>
              <Button
                type="submit"
                form="add-User"
                disableElevation // remove boxShadow
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingX: "20px",
                  paddingY: "8px",
                }}
                onClick={Alert}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Salvar
                </Typography>
                <ChevronRight sx={{ paddingBottom: "4px" }} />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default AddUserModal;
