import {
  CheckCircle,
  Delete,
  Error,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import CssTextField from "app/components/CssTextField";
import StyledIconButton from "app/components/StyledIconButton";
import brokersService from "app/services/apis/brokers";
import React, { useEffect, useState } from "react";
import BeneficaryFileUpload from "./BeneficiaresFileUpload";
import { useQuery } from "@tanstack/react-query";
import InfoIcon from '@mui/icons-material/Info';

export const BeneficiaryFormAAccordion = ({ props, index, is_submited }) => {
  const [biisValid, setBisIsvalid] = useState(false);
  const [helpertextMessage, setHelpertextMessage] = useState(null);
  const [isbiLoading, setIsLoading] = useState(false);
  const [biValidationRes,setBIValitionRes]=useState(true)
  const [provincesLis, setProvinces] = useState([]);
  const [loadingProvinces, setLoadingProvinces] = useState(false);
  const [nameValid, setValidName] = useState(
    props.formik.values?.beneficiaries?.[index]?.beneficiary_name ||
      "Novo beneficiário"
  );
  const { data: countriesLis } = useQuery(
    ["countries-data"],
    async () => {
      const axiosData = await brokersService.countries();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );
  const bi = (bi) => {
    setIsLoading(true);
     brokersService.validateBi(bi).then(({data})=>{
      console.log(data);
      setIsLoading(false);
      setBisIsvalid(true);
      props.formik.setFieldValue(
        `beneficiaries[${index}].beneficiary_name`,
        data.name
      );
      setValidName(data.name);
      props.formik.setFieldValue(
        `beneficiaries[${index}].beneficiary_gender`,
        data.gender
      );
      props.formik.setFieldValue(
        `beneficiaries[${index}].beneficiary_birthdate`,
        data.bithdate
      );
      props.formik.setFieldValue(
        `beneficiaries[${index}].beneficiary_id_number`,
        data.id_number
      );
    }).catch((err)=>{
      if(err?.response?.status>=500){
        setHelpertextMessage("Serviço Indisponivel");
        setBisIsvalid(true);
        setBIValitionRes(false)
      }else{
        setBIValitionRes(false)
        setBisIsvalid(true);
        setHelpertextMessage("Não foi possível validar o número de BI");
      }
      setIsLoading(false);

    });
  };

  const getproviences = async (countryID) => {
    setLoadingProvinces(true);
    const ax = await brokersService
      .provinces(countryID)
      .then((data) => {
        setProvinces(data);
        setLoadingProvinces(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingProvinces(false);
      });
  };

  useEffect(() => {
    getproviences(
      props.formik.values.beneficiaries[index]?.beneficiary_country_id
    );
  }, [props.formik.values.beneficiaries[index]?.beneficiary_country_id]);

  const { data: municepes, isLoading: loadingMuni } = useQuery(
    [
      "monicepes-data",
      props.formik.values.beneficiaries?.[index].beneficiary_address_zone_id,
    ],
    async () => {
      if (
        props.formik.values.beneficiaries?.[index]?.beneficiary_country_id == 6 && props.formik.values.beneficiaries?.[index]?.beneficiary_address_zone_id > 0
      ) {
        const ax = await brokersService.municipes(
          props.formik.values.beneficiaries?.[index]
            ?.beneficiary_address_zone_id
        );
        return ax.data;
      }
      return null;
    },
    { staleTime: 60000 * 0 }
  );

  useEffect(() => {
    if (
      props.formik.values.beneficiaries?.[index]?.beneficiary_id_number
        ?.length >= 14 &&
      isbiLoading !== true &&
      biisValid !== null
    ) {
      setBisIsvalid(true);
    }
  }, [props.formik.values.beneficiaries?.[index]?.beneficiary_id_number]);

  return (
    <Accordion sx={{ boxShadow: 0 }} expanded={props.formik.values.beneficiaries?.[index]?.expanded} onChange={()=>{
      props.formik.setFieldValue(
        `beneficiaries[${index}].expanded`,
        !props.formik.values.beneficiaries?.[index]?.expanded
      );
    }}>
      <AccordionSummary
        disableElevation
        sx={{
          backgroundColor: "#bf2f38",
          color: "white",
          borderRadius: "8px",
        }}
        expandIcon={<ExpandMoreOutlined sx={{ color: "white" }} color="#fff" />}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "0",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            {index + 1 + ". " + nameValid}
          </Typography>

          {props.formik.errors?.beneficiaries?.[index] != null ? (
            <IconButton sx={{ color: "#fff" }}>
              <Error sx={{ border: "2px solid red", borderRadius: "50%" }} />
            </IconButton>
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={12} spacing={2}>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Nacionalidade
            </Typography>
            <CssTextField
              fullWidth
              name="beneficiary_country_id"
              id="beneficiary_country_id"
              placeholder="País..."
              size="small"
              select
              type="text"
              InputProps={{ readOnly: is_submited }}
              value={
                props.formik.values.beneficiaries?.[index]
                  ?.beneficiary_country_id
              }
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={(eve) => {
                props.formik.setFieldValue(
                  `beneficiaries[${index}].beneficiary_country_id`,
                  eve.target.value
                );
              }}
              error={
                props.formik.touched.beneficiaries &&
                props.formik.touched.beneficiaries[index]
                  ?.beneficiary_country_id &&
                Boolean(
                  props.formik.errors.beneficiaries?.[index]
                    ?.beneficiary_country_id
                )
              }
              helperText={
                props.formik.touched.beneficiaries &&
                props.formik.touched.beneficiaries[index]
                  ?.beneficiary_country_id &&
                props.formik.errors.beneficiaries?.[index]
                  ?.beneficiary_country_id
              }
            >
              {countriesLis?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {props.formik.values.beneficiaries?.[index]?.beneficiary_country_id ==
          6  || is_submited == 1? (
            <Grid item xs={12} sm={6} spacing={2}>
              <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                Bilhete de Identificação<span style={{fontSize:'0.7rem'}}> (preencher com 0 em caso de Cédula)</span> 
              </Typography>
              <CssTextField
                fullWidth
                name="beneficiary_id_number"
                id="beneficiary_id_number"
                placeholder="Bilhete de Identidade..."
                size="small"
                inputProps={{maxLength: "14" , readOnly: is_submited }}
                value={
                  props.formik.values.beneficiaries?.[index]
                    ?.beneficiary_id_number
                }
                InputProps={
                  biisValid === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                           {biValidationRes? <CheckCircle sx={{ color: "green" }} />: <InfoIcon sx={{ color: "orange" }} /> } 
                          </InputAdornment>
                        ),
                      }
                    : isbiLoading
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }
                    : null
                }
                color="primary"
                sx={{ borderColor: "red" }}
                onBlur={props.formik.handleBlur}
                onChange={(e) => {
                  if (e.target.value.length == 14) {
                    if (
                      props.formik.values.beneficiaries?.find(
                        (beneficiary) =>
                          beneficiary.beneficiary_id_number === e.target.value
                      ) ||
                      props.formik.values.extras?.find(
                        (beneficiary) =>
                          beneficiary.beneficiary_id_number === e.target.value
                      )
                    ) {
                      setBisIsvalid(null);
                      setHelpertextMessage("Beneficiário já consta na lista");
                    } else {
                      bi(e.target.value);
                      setHelpertextMessage(null);
                    }
                  } else if (e.target.value.length > 14) {
                    setBisIsvalid(null);
                    setHelpertextMessage("BI inválido");
                  } else {
                    setBisIsvalid(null);
                    setHelpertextMessage(null);
                  }
                  props.formik.setFieldValue(
                    `beneficiaries[${index}].beneficiary_id_number`,
                    e.target.value
                  );
                }}
                error={
                  Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_id_number
                  ) ||
                  (helpertextMessage !== null && true)
                }
                helperText={
                  props.formik.errors.beneficiaries?.[index]
                    ?.beneficiary_id_number ||
                  (helpertextMessage !== null && helpertextMessage)
                }
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} spacing={2}>
              <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                Passaporte
              </Typography>
              <CssTextField
                fullWidth
                name="beneficiary_id_number"
                id="beneficiary_id_number"
                placeholder="Passaporte..."
                inputProps={{ readOnly: is_submited }}
                size="small"
                value={
                  props.formik.values.beneficiaries?.[index]
                    ?.beneficiary_id_number
                }
                color="primary"
                sx={{ borderColor: "red" }}
                onChange={(eve) => {
                  if (eve.target.value.length == 14) {
                    if (
                      props.formik.values.beneficiaries?.find(
                        (beneficiary) =>
                          beneficiary.beneficiary_id_number === eve.target.value
                      ) ||
                      props.formik.values.extras?.find(
                        (beneficiary) =>
                          beneficiary.beneficiary_id_number === eve.target.value
                      )
                    ) {
                      setBisIsvalid(null);
                      setHelpertextMessage("Beneficiário já consta na lista");
                    } else {
                      setBisIsvalid(true);
                      setHelpertextMessage(null);
                    }
                  } else if (eve.target.value.length !== 14) {
                    setBisIsvalid(true);
                    setHelpertextMessage(null);
                  } else {
                    setBisIsvalid(null);
                    setHelpertextMessage(null);
                  }

                  props.formik.setFieldValue(
                    `beneficiaries[${index}].beneficiary_id_number`,
                    eve.target.value
                  );
                }}
                error={
                  Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_id_number
                  ) ||
                  (helpertextMessage !== null && true)
                }
                helperText={
                  props.formik.errors.beneficiaries?.[index]
                    ?.beneficiary_id_number ||
                  (helpertextMessage !== null && helpertextMessage)
                }
              />
            </Grid>
          )}
          {biisValid === true ||
          props.formik.values.beneficiaries.isBeneficiary || isbiLoading || is_submited == 1 ? (
            <>
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Nome do beneficiário
                </Typography>
                <CssTextField
                  fullWidth
                  name="beneficiary_name"
                  id="beneficiary_name"
                  inputProps={{ readOnly: is_submited }}
                  placeholder="Nome..."
                  size="small"
                  type="text"
                  value={
                    props.formik.values.beneficiaries?.[index]?.beneficiary_name
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) => {
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_name`,
                      eve.target.value
                    );
                    setValidName(eve.target.value);
                  }}
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]?.beneficiary_name
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]?.beneficiary_name
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Gênero
                </Typography>
                <CssTextField
                  fullWidth
                  name="beneficiary_gender"
                  id="beneficiary_gender"
                  inputProps={{ readOnly: is_submited }}
                  placeholder="Gênero..."
                  select
                  size="small"
                  type="text"
                  value={
                    props.formik.values.beneficiaries?.[index]
                      ?.beneficiary_gender
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) =>
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_gender`,
                      eve.target.value
                    )
                  }
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_gender
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_gender
                  }
                >
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Feminino</MenuItem>
                </CssTextField>
              </Grid>

              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Data de Nascimento
                </Typography>
                <CssTextField
                  fullWidth
                  name="beneficiary_birthdate"
                  id="beneficiary_birthdate"
                  placeholder="Data de nascimen..."
                  size="small"
                  inputProps={{ readOnly: is_submited }}
                  type="date"
                  value={
                    props.formik.values.beneficiaries?.[index]
                      ?.beneficiary_birthdate
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) =>
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_birthdate`,
                      eve.target.value
                    )
                  }
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_birthdate
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_birthdate
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  {props.formik.values.beneficiaries?.[index]
                    ?.beneficiary_country_id == 6
                    ? "Província"
                    : "Cidade"}
                </Typography>
                <CssTextField
                  fullWidth
                  inputProps={{ readOnly: is_submited }}
                  name="beneficiary_address_zone_id"
                  id="beneficiary_address_zone_id"
                  select
                  InputProps={
                    loadingProvinces && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ),
                    }
                  }
                  placeholder={
                    props.formik.values.beneficiaries?.[index]
                      ?.beneficiary_country_id == 6
                      ? "Província..."
                      : "Cidade ..."
                  }
                  size="small"
                  value={
                    props.formik.values.beneficiaries?.[index]
                      ?.beneficiary_address_zone_id
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) =>
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_address_zone_id`,
                      eve.target.value
                    )
                  }
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_address_zone_id
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_address_zone_id
                  }
                >
                  {provincesLis?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </CssTextField>
              </Grid>
              {props.formik.values.beneficiaries?.[index]
                ?.beneficiary_country_id == 6 ? (
                <Grid item xs={12} sm={6} spacing={2}>
                  <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                    Município
                  </Typography>
                  <CssTextField
                    fullWidth
                    name="beneficiary_address_city"
                    select
                    inputProps={{ readOnly: is_submited }}
                    id="beneficiary_address_city"
                    placeholder="Município..."
                    size="small"
                    value={
                      props.formik.values.beneficiaries?.[index]
                        ?.beneficiary_address_city
                    }
                    color="primary"
                    sx={{ borderColor: "red" }}
                    InputProps={
                      loadingMuni && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }
                    }
                    onChange={(eve) =>
                      props.formik.setFieldValue(
                        `beneficiaries[${index}].beneficiary_address_city`,
                        eve.target.value
                      )
                    }
                    error={Boolean(
                      props.formik.errors.beneficiaries?.[index]
                        ?.beneficiary_address_city
                    )}
                    helperText={
                      props.formik.errors.beneficiaries?.[index]
                        ?.beneficiary_address_city
                    }
                  >
                    {municepes?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Número de Telefone
                </Typography>
                <CssTextField
                  fullWidth
                  name="beneficiary_gsm"
                  id="beneficiary_gsm"
                  placeholder="Telefone..."
                  size="small"
                  inputProps={{ maxLength: "9", readOnly: is_submited }}
                  value={
                    props.formik.values.beneficiaries?.[index]?.beneficiary_gsm
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) =>
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_gsm`,
                      eve.target.value
                    )
                  }
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]?.beneficiary_gsm
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]?.beneficiary_gsm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Email
                </Typography>
                <CssTextField
                  fullWidth
                  name="beneficiary_email"
                  id="beneficiary_email"
                  placeholder="Email..."
                  inputProps={{ readOnly: is_submited }}
                  size="small"
                  value={
                    props.formik.values.beneficiaries?.[index]
                      ?.beneficiary_email
                  }
                  color="primary"
                  sx={{ borderColor: "red" }}
                  onChange={(eve) =>
                    props.formik.setFieldValue(
                      `beneficiaries[${index}].beneficiary_email`,
                      eve.target.value
                    )
                  }
                  error={Boolean(
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_email
                  )}
                  helperText={
                    props.formik.errors.beneficiaries?.[index]
                      ?.beneficiary_email
                  }
                />
              </Grid>
              <Grid item sx={12} sm={12}>
                <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                  Documentos de Identificação
                </Typography>
                <BeneficaryFileUpload
                  setFieldValue={props.formik.setFieldValue}
                  values={props.formik.values.beneficiaries?.[index]?.documents}
                  fileType="Bilhete de Identidade"
                  index={index}
                  type={"beneficiaries"}
                  disabled={is_submited}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </AccordionDetails>
      <AccordionActions sx={{ display: "flex", justifyContent: "end" }}>
        <StyledIconButton
          disabled={is_submited}
          sx={{ backgroundColor: "#bf2f38" }}
          onClick={() => {
            const indexBeneficiary =
              props.formik.values.beneficiaries.findIndex(
                (beneficiary) =>
                  beneficiary.beneficiary_id_number ===
                  props.titular.values.customer_id_num
              );

            if (indexBeneficiary !== -1) {
              if (index == indexBeneficiary) {
                props.formik.setFieldValue(`isBeneficiary`, false);
              }
            } else {
              props.formik.setFieldValue(`isBeneficiary`, false);
            }

            props.formik.values.beneficiaries.splice(index, 1);
            props.formik.setFieldValue("beneficiaries", [
              ...props.formik.values.beneficiaries,
            ]);
          }}
        >
          <Delete sx={{ color: "#fff", fontSize: "18px" }} />
        </StyledIconButton>
      </AccordionActions>
    </Accordion>
  );
};
