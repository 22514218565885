import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import brokersService from "app/services/apis/brokers";
import toast from "react-hot-toast";
import Dropzone from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SendIcon from "@mui/icons-material/Send";
import LinearProgress from "@mui/material/LinearProgress";


export default function PaymentUploadDialog({ onClose,paymentID }) {
    console.log(paymentID);
  const [filesErrorMesg, setFilesErrorMesg] = React.useState(null);
  const [isLoadingFile, setIsLoadingFile] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [isSubmiting, setSsSubmiting] = React.useState(false);


  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      setIsLoadingFile(true);
      const { data } = await brokersService.uploadFiles(formData);
      console.log(data);
      setFileData(data);
      setIsLoadingFile(false);
      setFilesErrorMesg(null);
    } catch (error) {
      setFilesErrorMesg(false);
      setFilesErrorMesg("Erro durante o upload:");
      setIsLoadingFile(false);
    }
  };

  const sendInvoice = () => {
    setSsSubmiting(true)
    brokersService
      .invoiceSubmit({
        filename:fileData.filename,
        mask:fileData.filemask,
        payment_id:paymentID,
      })
      .then(() => {
        toast.success("Fatura submetida com sucesso.");
        onClose();
      })
      .catch(() => toast.error("Erro ao submeter Fatura."))
      .finally(()=>{
        setSsSubmiting(false);
      })
      ;
  };

  return (
    <Dialog fullWidth={true} maxWidth={"xs"} open={true} onClose={onClose}>
      <DialogTitle>Submeter Fatura</DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box mt={2} />
        <Grid container spacing={2}>
          <Grid item container>
            <Dropzone
              acceptedFiles=".jpg,.pdf,.jpeg,.png"
              multiple={false}
              maxFiles={1}
              onDrop={(acceptedFiles, rejectedFiles) => {
                if (acceptedFiles.length > 0) {
                  uploadFile(acceptedFiles[0]);
                } else if (rejectedFiles.length > 0) {
                  setFilesErrorMesg(
                    "Limite máximo de ficheiros é: 1x2 e os tipos permitidos (.jpg,.pdf,.jpeg,.png)"
                  );
                } else {
                  setFilesErrorMesg(null);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  border={`0.1rem dashed #b0b0b0 `}
                  p="1rem"
                  sx={{
                    "&:hover": { cursor: "pointer" },
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 4,
                      alignItems: "center",
                    }}
                  >
                    <FileUploadOutlinedIcon sx={{ fontSize: "20px" }} />
                    <Typography sx={{ color: "#8E8E8E" }}>
                      Anexar Fatura (".jpg,.pdf,.jpeg,.png menos de 4MB")
                    </Typography>
                  </Box>
                  <input {...getInputProps()} />
                </Box>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={12}>
            {filesErrorMesg == null ? (
              isLoadingFile ? (
                <LinearProgress />
              ) : (
                <Typography>{fileData?.filemask}</Typography>
              )
            ) : (
              <Typography align="center" color={"red"}>
                Erro ao Carregar Ficheiro
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="passwordChange-form"
          disableElevation
          variant="contained"
          disabled={(fileData ? false : true)|| isSubmiting}
          size="small"
          sx={{
            borderRadius: "8px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            paddingX: "20px",
            paddingY: "8px",
          }}
          onClick={() => sendInvoice()}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
            Submeter
          </Typography>
          <SendIcon sx={{ paddingBottom: "4px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
