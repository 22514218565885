import { ClearAll, Details } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import FilterListIcon from "@mui/icons-material/FilterList";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { getContrastColor } from "@jumbo/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddUserModal from "./AddUserModal";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import UserDetailsModal from "./UserDetails";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
const ListUsers = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [entity_name, setEntity_name] = useState();
  const [position, setPosition] = useState();
  const [user_group, setUser_group] = useState();
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();
  const [status, setStatus] = useState();
  const [openModal, setOpenModal] = useState(null);
  const [openModalDetails, setOpenModalDetail] = useState(null);

  const data = {
    rows: [
      {
        id: 145,
        entity_name: "Danilson Kayumbuca",
        position: "Developer",
        user_group: "Admin",
        contact: "123-456-7890",
        email: "danilson.kayumbuca@medicare.ao",
        status: "Activo",
        status_color: "green",
      },
      // ... (existing data)
    ],
    currentPage: 1,
    totalRowCount: 6,
  };

  // 
  for (let index = 0; index < 50; index++) {
    data.rows.push({
      id: index + 1, // Adjust the ID based on your requirements
      entity_name: `User ${index + 1}`,
      position: "Developer",
      user_group: "Admin",
      contact: "123-456-7890",
      email: `user${index + 1}@example.com`,
      status: "Activo",
      status_color: "green",
    });
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "ID",
        size: 50,
      },
      {
        id: "name",
        accessorKey: "entity_name",
        header: "NOME",
      },
      {
        id: "position",
        accessorKey: "position",
        header: "CARGO",
      },
      {
        id: "user_group",
        accessorKey: "user_group",
        header: "TIPO DE USUÁRIO",
      },
      {
        id: "contact",
        accessorKey: "contact",
        header: "CONTACTO",
      },
      {
        id: "email",
        accessorKey: "email",
        header: "EMAIL",
      },
      {
        id: "status",
        accessorKey: "status",
        header: "ESTADO",
        Cell: ({ row }) => (
          <Box>
            <Chip
              sx={{
                fontWeight: 500,
                fontSize: ".800rem",
                color: "white",
                backgroundColor: `${row.original.status_color}`,
              }}
              label={row.original.status}
            />
          </Box>
        ),
        size: 20,
      },
    ],
    []
  );

  const clearFilters = () => {
    setEntity_name("");
    setPosition("");
    setUser_group("");
    setContact("");
    setEmail("");
    setStatus("");
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };
  const handleCloseDetailsModal = () => {
    setOpenModalDetail(null);
  };

  return (
    <JumboCardQuick
      title={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginX: 0,
            mb: 1,
          }}
        >
          <Typography sx={{ color: "#5d5d5d" }} fontWeight={900} variant="h1">
            Utilizadores
          </Typography>
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Novo
          </Button>
        </Box>
      }
      bgColor={"#ffff"}
      noWrapper
      sx={{
        height: "100%",
        boxShadow: "0",
        color: "black",
        borderRadius: 0,
        mx: { xs: -4, lg: -6 },
        mt: -4,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ paddingLeft: "2rem", paddingRight: "3rem" }}
      >
        <Grid item xs={12} sx={{ m: 2 }}>
          <MaterialReactTable
            columns={columns}
            initialState={{ showColumnFilters: false, density: "compact" }}
            data={data?.rows ?? []}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={() => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => setOpenModalDetail(true)}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="primary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
            enableRowSelection={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableColumnOrdering={false}
            enableColumnFilterMode={false}
            enableHiding={false}
            enableFilters={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            rowCount={data?.totalRowCount ?? 0}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isFetching,
              sorting,
            }}
            muiTableHeadCellProps={{
              sx: {
                border: "1px solid #eee",
                width: "max-content",
              },
              "& th:last-child": {
                width: "20px",
                border: "1px solid blue",
              },
            }}
            muiTableProps={{
              sx: {
                border: "1px solid #eee",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                border: "1px solid #F4F4F7",
              },
            }}
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: "error",
                    children: "Erro ao tentar carregar lista.",
                  }
                : undefined
            }
            muiTableBodyRowProps={{ hover: false }}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            muiTablePaginationProps={{ sx: { backgroundColor: "#FFFFFF" } }}
            muiBottomToolbarProps={{ sx: { backgroundColor: "#FFFFFF" } }}
            muiTopToolbarProps={{
              sx: { backgroundColor: "white", marginBottom: "25px" },
            }}
            muiTableHeadRowProps={{
              sx: {
                background: "#fff",
                "& .MuiTableCell-head": {
                  color: "#000",
                },
                border: "0.5px solid #eee",
              },
            }}
            enableTableHead={true}
            muiTablePaperProps={{ sx: { boxShadow: "none" } }}
            manualFiltering
            manualPagination
            manualSorting
            localization={""}
            muiTableBodyProps={{
              sx: (theme) => ({
                "& tr": {
                  backgroundColor: "#FFF",
                },
                "& tr > td": {
                  border: "0.5px solid #eee",
                },
              }),
            }}
            /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: expandFilters === true ? "2px" : "0px",
                    }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <IconButton
                        onClick={() => {
                          setExpandFilters((prev) => !prev);
                        }}
                      >
                        <FilterListIcon sx={{ fontSize: 20, color: "#000" }} />
                      </IconButton>
                      <Typography sx={{ color: "#000" }}>
                        Filtrar por
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name="name"
                            defaultValue=""
                            label="Nome"
                            value={entity_name}
                            size="small"
                            onChange={(ev) => setEntity_name(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            defaultValue=""
                            name="position"
                            label="Cargo"
                            value={position}
                            size="small"
                            onChange={(ev) => setPosition(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            defaultValue=""
                            name="user_group"
                            label="Tipo"
                            value={user_group}
                            size="small"
                            onChange={(ev) => setUser_group(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            type="number"
                            name="contact"
                            fullWidth
                            defaultValue=""
                            label="Telefone"
                            value={contact}
                            size="small"
                            onChange={(ev) => setContact(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="email"
                            name="email"
                            defaultValue=""
                            label="Email"
                            value={email}
                            size="small"
                            onChange={(ev) => setEmail(ev.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            select
                            name="status"
                            label="Estado"
                            value={status}
                            size="small"
                            onChange={(ev) => setStatus(ev.target.value)}
                          >
                            {[
                              { id: 1, name: "Activo" },
                              { id: 2, name: "Inactivo" },
                            ].map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              clearFilters();
                            }}
                          >
                            <ClearAll sx={{ color: "#000" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>

      {openModal && (
        <AddUserModal open={openModal} handleCloseModal={handleCloseModal} />
      )}
      {openModalDetails && (
        <UserDetailsModal
          modalDetails={openModalDetails}
          handleCloseDetailsModal={handleCloseDetailsModal}
        />
      )}
    </JumboCardQuick>
  );
};

export default ListUsers;
