import React from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import { CardMedia, IconButton, InputAdornment, Slide, TextField, useMediaQuery } from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "./Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import Search from "@mui/icons-material/Search";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const matches = useMediaQuery("(min-width:517px)");

  return (
    <React.Fragment>
      <>
        {(sidebarOptions.style === SIDEBAR_STYLES.FULL_HEIGHT ||
          sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT ||
          (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)) && (
          <IconButton
            aria-label="open drawer"
            className="i-btn"
            onMouseEnter={() => setSidebarOptions({ open: !sidebarOptions.open })}
            sx={{
              ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? 2 : 0,
              mr: 0.5,
              bgcolor: "transparent",
              color: "#fff",
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
          >
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <Logo />
      </>
      <Stack direction="row" alignItems="center" spacing={1.25} sx={{ ml: "auto" }}>
        <AuthUserDropdown />
      </Stack>
    </React.Fragment>
  );
};

export default Header;
