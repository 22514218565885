import React from "react";
import usePlanCalculation from "./usePlanCalculation";
import { Grid, Typography, Box } from "@mui/material";
import CssTextField from "app/components/CssTextField";
import FileUpload from "../FileUpload";

const Multicaixa = (props) => {
  const { referenceData } = props;
  const { data, isLoading } = usePlanCalculation(props.extras, props.planID);
  return (
    <>
      {referenceData && (
        <>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                paddingTop: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Typography sx={{ paddingTop: "4px" }}>
                Estimado(a) {referenceData?.customer_name},
              </Typography>
              <Typography sx={{ paddingTop: "10px" }}>
                Escolha a opcão PAGAMENTOS {">"} PAGAMENTOS POR REFERENCIA e
                preencha:
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "gray", fontWeight: "500" }}>
                  {" "}
                  Contacto{" "}
                </span>
                : {referenceData?.mobile}
              </Typography>
              <Typography>
                <span style={{ color: "gray", fontWeight: "500" }}>
                  {" "}
                  Entidade{" "}
                </span>{" "}
                : {referenceData?.entity}
              </Typography>
              <Typography sx={{ color: "#bf2f38" }}>
                {" "}
                <span style={{ color: "gray", fontWeight: "500" }}>
                  {" "}
                  Referencia{" "}
                </span>
                : {referenceData?.reference}
              </Typography>
              <Typography sx={{ color: "#bf2f38" }}>
                <span style={{ color: "gray", fontWeight: "500" }}>
                  Montante
                </span>
                : {referenceData?.amount}
              </Typography>
              <Typography>
                <span style={{ color: "gray", fontWeight: "500" }}>
                  Data Limite
                </span>
                : {referenceData?.date_end}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={6} sx={12}>
        <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
          Contacto para envio da Referência
        </Typography>
        <CssTextField
          fullWidth
          name="phone_number"
          placeholder="Contacto"
          id="phone_number"
          size="small"
          value={props.formik.values.phone_number}
          color="primary"
          inputProps={{ readOnly: props.submited, maxLength: "9" }}
          onChange={(ev) => {
            props.formik.setFieldValue("phone_number", ev.target.value);
          }}
          error={
            props.formik.touched.phone_number &&
            Boolean(props.formik.errors.phone_number)
          }
          helperText={
            props.formik.touched.phone_number &&
            props.formik.errors.phone_number
          }
        />
      </Grid>
      <Grid item md={6}>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            width: "100%",
            paddingBottom: "10px",
          }}
        >
          <Typography variant="subtitle2">Proposta de Adesão</Typography>
          <Typography sx={{ color: "#d35400" }}>( Obrigatório)</Typography>
        </Box>
        <FileUpload
          setFieldValue={props.formik.setFieldValue}
          values={props.formik.values.attachaments?.proposal}
          fileType="documentos"
          type={"proposal"}
          document_type_id={1}
          disabled={props.submited}
        />
      </Grid>
      <Grid item md={6}>
        {isLoading ? (
          <Typography textAlign={"center"}>Atualizando</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              textAlign: "justify",
            }}
          >
            <Typography>
              <strong>Plano base</strong>:{" "}
              {props.beneficiaries?.values?.beneficiaries.length}{" "}
              beneficiário(s) de {props.maxBaseMembers} incluído (s) :
              <span style={{ color: "#bf2f38", fontWeight: "500" }}>
                {" " + data?.base_value + " kz "}
              </span>
              / {data?.billing_cycle_unit_formatted}
            </Typography>
            {props.beneficiaries?.values?.extras.length > 0 ? (
              <Typography>
                <strong>Beneficiários Extras </strong>:{" "}
                {props.beneficiaries?.values?.extras.length} *
                {" " + data?.extra_member_amount + " kz "} :{" "}
                <span style={{ color: "#bf2f38", fontWeight: "500" }}>
                  {" " + data?.total_extra_amount + " kz "}
                </span>
                / {data?.billing_cycle_unit_formatted}
              </Typography>
            ) : null}

            <Typography variant="h4" marginTop={2}>
              Total a Pagar {data?.billing_cycle_unit_formatted2}{" "}
              <span style={{ color: "#bf2f38" }}>
                {" " + data?.billing + " kz "}
              </span>
              / {data?.billing_cycle_unit_formatted}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Multicaixa;
