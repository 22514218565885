import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../../../hooks/useAuth";


const PrivateRoute = ({ children }) => {
  console.log("Private Route");
  const { user } = useAuth();
  console.log("Auth user" + JSON.stringify(user));
  if (user === null) {
    return (
      <Backdrop
        sx={{
          backgroundColor: "white",
          color: "red",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (user) {
    return (
      <>
        {children}
      </>
    );
  }

  return <Navigate to={"/login"} replace />;
};

export default PrivateRoute;
