import { Box, Card, Typography } from "@mui/material";
import React from "react";

const CounterCard = (props) => {
  return (
    <Card>
      <Box display={"flex"} gap={1} flexDirection={"column"}>
        <Box sx={{ display: "flex", p: 0.5, mb: 1, backgroundColor: props.bgColor }}></Box>
        <Box flex={1} minHeight={75}>
          <Typography align="center" fontWeight={400}>
            {props.title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography align="center" fontSize={"1rem"} fontWeight={700} variant="h4">
          {props.total}
        </Typography>
        {props?.secondResult && (
          <Typography align="center" fontSize={"1rem"}>
            {props?.secondTitle} <strong>{props?.secondResult}</strong>
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default CounterCard;
