import {Download } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CssTextField from "app/components/CssTextField";
import brokersService from "app/services/apis/brokers";
import styled from "@emotion/styled";
import toast from "react-hot-toast";
import DirectDebit from "./Payment/DirectDebit";
import BankTransfer from "./Payment/BankTransfer";
import Multicaixa from "./Payment/Multicaixa";

const PaymentStep = (props) => {
  const { is_submited } = props;
  const { referenceData } = props;
  const [isLodaingMethods, setLoadingMethods] = useState(false);
  const [methodsList, setMehodsList] = useState(null);
  const [activeMethod, setActiveMethod] = useState(null);


  const getMethods = async () => {
    if (props.plan?.values?.subscription_plan_id != null) {
      setLoadingMethods(true);
      const axiosData = await brokersService.getpaymentMethods(
        props.plan?.values?.subscription_plan_id
      );
      setMehodsList(axiosData.data);
      setLoadingMethods(false);
    }
  };

  useEffect(() => {
    if (props.plan?.values?.subscription_plan_id != null) {
      getMethods();
    }
  }, []);


  useEffect(() => {
    if (props.formik.values.payment_method_id !== null) {
      setActiveMethod(
        methodsList?.find(
          (method) => method.id == props.formik.values.payment_method_id
        )?.id
      );
    }
  }, [props.formik.values.payment_method_id, methodsList]);


  const MediaMobile = useMediaQuery("(max-width:480px)");
  const downloadPropostaAdesao = async () => {
    //await props.save();
    const toastId = toast.loading('Descarregando...');
    brokersService
      .downloadPropostaAdesao({
        subscription_plan_id: props.plan?.values.subscription_plan_id,
        customer: props.titular.values,
        payment_method_id:activeMethod,
        beneficiaries: [
          ...props.beneficiaries.values.beneficiaries,
          ...props.beneficiaries.values.extras,
        ],
        saleID: props.plan?.values.sale_id,
        iban: props.formik.values.iban,
      }).catch(()=> {
        toast.error("Erro ao descarregar ficheiro")
      }).finally(()=>{
        toast.dismiss(toastId);
      })
      
  };


  return (
    <Grid container spacing={5} paddingTop={5}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
          Método de pagamento
        </Typography>
        <CssTextField
          fullWidth
          name="payment_method_id"
          id="payment_method_id"
          select
          inputProps={{ readOnly: is_submited }}
          size="small"
          value={props.formik.values.payment_method_id}
          color="primary"
          onChange={(ev) => {
            props.formik.handleChange(ev);
            props.save(ev.target.value);
            setActiveMethod(ev.target.value);
          }}
          InputProps={
            isLodaingMethods
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }
              : null
          }
        >
          {methodsList?.map((data) => (
            <MenuItem key={data.id} value={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </CssTextField>
      </Grid>
      
      <Grid item xs={12} sm={6}>
      {activeMethod?
      <React.Fragment>
        <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
          Proposta de adesão com {activeMethod==6?'autorização de debito':''}
        </Typography>
        <Button
          disableElevation
          disabled={is_submited || (activeMethod==6  && props.formik.values?.iban?.length != 26)}
          type="submit"
          form="add-User"
          variant="contained"
          sx={{
            borderRadius: "8px",
            display: "flex",
            gap: MediaMobile ? "5px" : "10px",
            alignItems: "center",
            justifyContent: "center",
            paddingX: "20px",
            paddingY: "8px",
          }}
          onClick={async () => {
            downloadPropostaAdesao();
          }}
        >
          <Download sx={{ paddingBottom: "4px" }} />
          <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
            Download da Proposta de adesão
          </Typography>
        </Button>
        </React.Fragment>
        :null}
      </Grid>
      {activeMethod==6?
      <DirectDebit 
          formik={props.formik} 
          beneficiaries={props.beneficiaries} 
          planID={props.plan?.values?.subscription_plan_id} 
          extras={props.beneficiaries?.values?.extras.length} 
          maxBaseMembers={props.maxBaseMembers} 
          submited={is_submited}
          
          />
      :null}
      {activeMethod==4?
      <Multicaixa 
        formik={props.formik} 
        beneficiaries={props.beneficiaries}
        planID={props.plan?.values?.subscription_plan_id} 
        extras={props.beneficiaries?.values?.extras.length} 
        maxBaseMembers={props.maxBaseMembers}
        submited={is_submited}
        referenceData={referenceData}
      />:null}

    </Grid>
  );
};

export default PaymentStep;
