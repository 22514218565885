import { useQuery } from "@tanstack/react-query";
import brokersService from "app/services/apis/brokers";
import React from "react";
import ReactApexChart from "react-apexcharts";

const BarStackedVertical = ({ width, title = "", style = null, data: graphicData }) => {
  console.log(graphicData);
  const dataStructure = {
    series: graphicData?.data ?? [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#bf2f38", "#13d8aa"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: "70%",
          barHeight: 200,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // type: "datetime",
        categories: graphicData?.categories ?? [],
      },
      yaxis: {
        labels: {
          formatter: (val) => (val >= 1e3 && val < 1e6 ? val / 1e3 + "k" : val >= 1e6 ? val / 1e6 + "M" : val),
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };
  return (
    <div id="chart" style={style}>
      <h4>{title}</h4>
      <ReactApexChart options={dataStructure.options} series={dataStructure.series} type="bar" width={width} height={350} />
    </div>
  );
};

export default BarStackedVertical;
