import { Box, Button } from '@mui/material';
import React from 'react';

const HomePage = () => {
    return (
        
        <Box 
        sx={{
            mx: { xs: -4, lg: -6 },
            mt: -4,
        }}
        >
            <img src='https://cdn.medicare.ao/broker/manuais/fundo1.fw.jpg' width={'100%'}/>
        </Box>
    );
};

export default HomePage;