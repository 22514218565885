import { AccountCircle, CheckCircle } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CssTextField from "app/components/CssTextField";
import brokersService from "app/services/apis/brokers";
import React, { useEffect, useState } from "react";
import TitularFileUpload from "./TitularFileUpload";
import { useQuery } from "@tanstack/react-query";
import { ErrorIcon } from "react-hot-toast";
import InfoIcon from '@mui/icons-material/Info';
const IdentificationStep = (props) => {

  const { is_submited } = props;


  const [biisValid, setBisIsvalid] = useState(false);
  const [biValidationRes,setBIValitionRes]=useState(true)
  const [helpertextMessage, setHelpertextMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [loadingProvinces, setLoadingProvinces] = useState(false);
  const { data: countriesLis } = useQuery(
    ["countries-data"],
    async () => {
      const axiosData = await brokersService.countries();
      return axiosData.data;
    },
    { staleTime: 60000 * 60 }
  );

  const bi = (bi) => {
    setIsLoading(true);
     brokersService.validateBi(bi).then(({data})=>{
      console.log(data);
      setIsLoading(false);
      setBisIsvalid(true);
      props.formik.setFieldValue("customer_name", data.name);
      props.formik.setFieldValue("customer_birthdate", data.bithdate);
      props.formik.setFieldValue("customer_gender", data.gender);
      props.formik.setFieldValue("customer_id_num", data.id_number);
      props.formik.setFieldValue("customer_vat", data.id_number);
    }).catch((err)=>{
      if(err?.response?.status>=500){
        setHelpertextMessage("Serviço Indisponivel");
        setBisIsvalid(true);
        setBIValitionRes(false)
      }else{
        setBIValitionRes(false)
        setBisIsvalid(true);
        setHelpertextMessage("Não foi possível validar o número de BI");
      }
      setIsLoading(false);

    });
  };

  const getproviences = async (countryID) => {
    setLoadingProvinces(true);
    const ax = await brokersService
      .provinces(countryID)
      .then((data) => {
        setProvinces(data);
        setLoadingProvinces(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getproviences(props.formik.values.customer_address_country_id);
  }, [props.formik.values.customer_address_country_id]);

  const { data: monicepes, isLoading2: loadingMuni } = useQuery(
    ["provinces-data", props.formik.values.customer_address_zone_id],
    async () => {
      if (props.formik.values.customer_address_country_id == 6 && props.formik.values.customer_address_zone_id > 0 ) {
        const ax = await brokersService.municipes(
          props.formik.values.customer_address_zone_id
        );
        return ax.data;
      }
      return null;
    },
    { staleTime: 60000 * 0 }
  );

  const handleInputChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");

    const formattedNumber = inputNumber.replace(/(\d{4})(?=\d)/g, "$1-");

    return formattedNumber;
  };
  
  useEffect(() => {
    if (
      props.formik.values.customer_id_num?.length >= 14 &&
      isLoading !== true
    ) {
      setBisIsvalid(true);
    }
  }, [props.formik.values.customer_id_num]);

  const MediaMobile = useMediaQuery("(max-width:480px)");
  const MediaDesktop = useMediaQuery("(max-width:480px)");

  return (
    <Grid container spacing={2} paddingTop={MediaMobile ? 1 : 6}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
          Nacionalidade
        </Typography>
        <CssTextField
          fullWidth
          name="customer_address_country_id"
          id="customer_address_country_id"
          select
          size="small"
          label="Selecione a nacionalidade"
          InputProps={{ readOnly: is_submited }}
          value={props.formik.values.customer_address_country_id}
          color="primary"
          onChange={(ev) => {
            props.formik.handleChange(ev);
          }}
        >
          {countriesLis?.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item.name}
            </MenuItem>
          ))}
        </CssTextField>
      </Grid>
      {props.formik.values.customer_address_country_id == 6 ? (
        <Grid item xs={12} sm={6} spacing={2}>
          <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
            Bilhete de Identificação
          </Typography>
          <CssTextField
            fullWidth
            name="customer_id_num"
            id="customer_id_num"
            placeholder="Bilhete de Identidade..."
            size="small"
            value={props.formik.values.customer_id_num}
            minRows={14}
            color="primary"
            sx={{ borderColor: "red" }}
            inputProps={{maxLength: "14", readOnly: is_submited }}
            InputProps={
              biisValid === true
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                       {biValidationRes? <CheckCircle sx={{ color: "green" }} />: <InfoIcon sx={{ color: "orange" }} /> } 
                      </InputAdornment>
                    ),
                  }
                : isLoading
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ),
                  }
                : null
            }
            onChange={(e) => {
              if (e.target.value.length >= 14) {
                bi(e.target.value);
              } else {
                setBisIsvalid(false);
                setHelpertextMessage(null);
              }
              props.formik.handleChange(e);
            }}
            error={
              props.formik.touched.customer_id_num &&
              Boolean(props.formik.errors.customer_id_num)
            }
            helperText={
              (props.formik.touched.customer_id_num &&
                props.formik.errors.customer_id_num) ||
              helpertextMessage
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} spacing={2}>
          <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
            Passaporte
          </Typography>
          <CssTextField
            fullWidth
            name="customer_id_num"
            id="customer_id_num"
            placeholder="Passaporte..."
            size="small"
            value={props.formik.values.customer_id_num}
            inputProps={{ readOnly: is_submited }}
            color="primary"
            sx={{ borderColor: "red" }}
            onChange={(e) => {
              props.formik.handleChange(e);
            }}
            error={
              props.formik.touched.customer_id_num &&
              Boolean(props.formik.errors.customer_id_num)
            }
            helperText={
              props.formik.touched.customer_id_num &&
              props.formik.errors.customer_id_num
            }
          />
        </Grid>
      )}

      {biisValid === true ||
      props.formik.values.customer_address_country_id != 6 ? (
        <>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Nome do Titular
            </Typography>
            <CssTextField
              fullWidth
              inputProps={{ readOnly: is_submited }}
              name="customer_name"
              id="customer_name"
              placeholder="Nome..."
              size="small"
              type="text"
              value={props.formik.values.customer_name}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={(ev) => {
                props.formik.handleChange(ev);

                if (props.beneficiaries.values.isBeneficiary) {
                  const index =
                    props.beneficiaries.values.beneficiaries.findIndex(
                      (beneficiary) =>
                        beneficiary.beneficiary_id_number ===
                        props.formik.values.customer_id_num
                    );
                  props.beneficiaries.setFieldValue(
                    `beneficiaries[${index}].beneficiary_name`,
                    ev.target.value
                  );
                }
              }}
              error={
                props.formik.touched.customer_name &&
                Boolean(props.formik.errors.customer_name)
              }
              helperText={
                props.formik.touched.customer_name &&
                props.formik.errors.customer_name
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Data de Nascimento
            </Typography>
            <CssTextField
              fullWidth
              inputProps={{ readOnly: is_submited }}
              name="customer_birthdate"
              id="customer_birthdate"
              placeholder="Nome..."
              size="small"
              type="date"
              value={props.formik.values.customer_birthdate}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={props.formik.handleChange}
              error={
                props.formik.touched.customer_birthdate &&
                Boolean(props.formik.errors.customer_birthdate)
              }
              helperText={
                props.formik.touched.customer_birthdate &&
                props.formik.errors.customer_birthdate
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Gênero
            </Typography>
            <CssTextField
              fullWidth
              name="customer_gender"
              id="customer_gender"
              placeholder="Género..."
              select
              inputProps={{ readOnly: is_submited }}
              size="small"
              value={props.formik.values.customer_gender}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={props.formik.handleChange}
              error={
                props.formik.touched.customer_gender &&
                Boolean(props.formik.errors.customer_gender)
              }
              helperText={
                props.formik.touched.customer_gender &&
                props.formik.errors.customer_gender
              }
            >
              {[
                { value: "F", name: "Feminino" },
                { value: "M", name: "Masculino" },
              ].map((item) => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              {props.formik.values.customer_address_country_id == 6
                ? "Província"
                : "Cidade"}
            </Typography>
            <CssTextField
              fullWidth
              name="customer_address_zone_id"
              id="customer_address_zone_id"
              select
              inputProps={{ readOnly: is_submited }}
              InputProps={
                loadingProvinces && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }
              }
              placeholder={
                props.formik.values.customer_address_country_id == 6
                  ? "Província..."
                  : "Cidade ..."
              }
              size="small"
              value={props.formik.values.customer_address_zone_id}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={(ev) => {
                props.formik.handleChange(ev);
              }}
              error={
                props.formik.touched.customer_address_zone_id &&
                Boolean(props.formik.errors.customer_address_zone_id)
              }
              helperText={
                props.formik.touched.customer_address_zone_id &&
                props.formik.errors.customer_address_zone_id
              }
            >
              {provinces?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {props.formik.values.customer_address_country_id == 6 ? (
            <Grid item xs={12} sm={6} spacing={2}>
              <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
                Município
              </Typography>
              <CssTextField
                fullWidth
                name="customer_address_city"
                inputProps={{ readOnly: is_submited }}
                select
                InputProps={
                  loadingMuni && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ),
                  }
                }
                id="customer_address_city"
                placeholder="Município..."
                size="small"
                value={props.formik.values.customer_address_city}
                color="primary"
                sx={{ borderColor: "red" }}
                onChange={props.formik.handleChange}
                error={
                  props.formik.touched.customer_address_city &&
                  Boolean(props.formik.errors.customer_address_city)
                }
                helperText={
                  props.formik.touched.customer_address_city &&
                  props.formik.errors.customer_address_city
                }
              >
                {monicepes?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Número de Telefone
            </Typography>
            <CssTextField
              fullWidth
              name="customer_gsm"
              id="customer_gsm"
              placeholder="Telefone..."
              size="small"
              type="text"
              inputProps={{ maxLength: "9", readOnly: is_submited }}
              value={props.formik.values.customer_gsm}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={(ev) => {
                props.formik.handleChange(ev);

                props.payment.setFieldValue("phone_number", ev.target.value);
              }}
              error={
                props.formik.touched.customer_gsm &&
                Boolean(props.formik.errors.customer_gsm)
              }
              helperText={
                props.formik.touched.customer_gsm &&
                props.formik.errors.customer_gsm
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Email
            </Typography>
            <CssTextField
              fullWidth
              name="customer_email"
              id="customer_email"
              placeholder="email..."
              inputProps={{ readOnly: is_submited }}
              size="small"
              type="email"
              value={props.formik.values.customer_email}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={props.formik.handleChange}
              error={
                props.formik.touched.customer_email &&
                Boolean(props.formik.errors.customer_email)
              }
              helperText={
                props.formik.touched.customer_email &&
                props.formik.errors.customer_email
              }
            />
          </Grid>{" "}
{/*           <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              IBAN
            </Typography>
            <CssTextField
              fullWidth
              name="customer_iban"
              id="customer_iban"
              size="small"
              type="text"
              inputProps={{ maxLength: 26, readOnly: is_submited }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        color: "gray",
                        fontSize: "15px",
                        paddingRight: "1px",
                      }}
                    >
                      AO06
                    </Box>
                  </InputAdornment>
                ),
              }}
              value={props.formik.values.customer_iban}
              color="primary"
              sx={{ borderColor: "red" }}
              onChange={(e) => {
                props.formik.setFieldValue(
                  "customer_iban",
                  handleInputChange(e)
                );
              }}
              error={
                props.formik.touched.customer_iban &&
                Boolean(props.formik.errors.customer_iban)
              }
              helperText={
                props.formik.touched.customer_iban &&
                props.formik.errors.customer_iban
              }
            />
          </Grid> */}
          <Grid item sx={12} sm={12}>
            <Box display={'flex'} flexDirection={'row'} gap={1}>
            <Typography variant="subtitle2" sx={{ paddingBottom: "8px" }}>
              Documentos de Identificação
            </Typography>
            <Typography sx={{ color: "#d35400" }}>( Obrigatório)</Typography>
            </Box>
            <TitularFileUpload
              setFieldValue={props.formik.setFieldValue}
              values={props.formik.values.documents}
              fileType="Bilhete de Identidade"
              disabled={is_submited}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default IdentificationStep;
