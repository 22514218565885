import React from "react";
/* import Home from "../pages/home";
import DemoPage from "../pages/home/DemoPage";
import UsersPage from "../pages/home/UsersPage"; */
import Login1 from "../pages/login1";

import Page from "@jumbo/shared/Page";
import Page404 from "app/pages/ErrorPapes/Page404";
import DashBoardPage from "app/pages/DashboardPage";
import PublicRoute from "./middleware/PublicRoute";
import PrivateRoute from "./middleware/PrivateRoute";
import usersRoutes from "app/pages/Users";
import WalletRoute from "app/pages/Wallet/Index";
import particularPlansRoutes from "app/pages/particularPlans";
import salesRoute from "app/pages/Sales/Index";
import Guide from "app/pages/Manual";
import ContactPage from "app/pages/ContactPage";
//import prospectRoutes from "app/pages/Prospect";
import HomePage from "app/pages/home/Home";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Page component={HomePage} />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Page component={DashBoardPage} />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: (
      <PrivateRoute>
        <Page component={Page404} />
      </PrivateRoute>
    ),
  },
  {
    path: "/guide",
    element: (
      <PrivateRoute>
        <Page component={Guide} />
      </PrivateRoute>
    ),
  },
  {
    path: "/contacts",
    element: (
      <PrivateRoute>
        <Page component={ContactPage} />
      </PrivateRoute>
    ),
  },
  ...usersRoutes,
  ...WalletRoute,
  ...salesRoute,
  ...particularPlansRoutes,
  //...prospectRoutes,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Page layout={"solo-page"} component={Login1} />
      </PublicRoute>
    ),
  },
  {
    path: "*",
    element: (
      <PublicRoute>
        <Page layout={"solo-page"} component={Page404} />
      </PublicRoute>
    ),
  },
];

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
