import React, { useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { FilterList } from "@mui/icons-material";
import DatePickerField from "@jumbo/components/DatePickerField";
import { useCustomQuery } from "app/hooks/useCustomQuery";
import brokersService from "app/services/apis/brokers";
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const columns = [
  {
    id: "type",
    accessorKey: "type",
    header: "ID",
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Descrição",
  },
  {
    id: "amount",
    accessorKey: "amount",
    header: "Montante",
    Cell: ({ row }) => (
      <span
        style={{
          color: +row.original.sign === "-" ? "#991B1B" : "#166534",
        }}
      >
        {row.original.amount}
      </span>
    ),
  },
  {
    id: "balance",
    accessorKey: "balance",
    header: "Saldo",
    Cell: ({ row }) => (
      <span
        style={{
          color: "#166534",
        }}
      >
        {row.original.balance}
      </span>
    ),
  },
  {
    id: "date_created",
    accessorKey: "date_created",
    header: "Data/Tempo",
  },
];

const ListWallet = () => {
  const  isMobile = useMediaQuery("(max-width: 768px)");
  const [expandFilters, setExpandFilters] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [balance, setBalance] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data, isError, isFetching, isLoading } = useCustomQuery(
    [
      "currentAccount-data",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      startDate,
      endDate,
    ],
    async () => {
      const axiosData = await brokersService.getCurrentAccount(
        pagination,
        sorting,
        startDate,
        endDate
      );
      setBalance(axiosData.data.totalRowAmount);
      return axiosData.data;
    },
    { debounce: 350, keepPreviousData: true, staleTime: 6000 * 1 }
    /* { keepPreviousData: false, staleTime: 60000 * 5 } */
  );
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: "compact" }}
          defaultDisplayColumn={{
            minSize: 5, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 5, //make columns wider by default
          }}
          default
          enableColumnResizing
          positionActionsColumn="last"
          // renderRowActions={(r) => (
          //   <IconButton color="primary">
          //     <Visibility />
          //   </IconButton>
          // )}
          // enableRowActions
          data={data?.rows ?? []}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableColumnActions={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          displayColumnDefOptions={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          rowCount={data?.totalRowCount ?? 0}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
          muiTableHeadCellProps={{
            sx: {
              border: "1px solid #eee",
              width: "max-content",
            },
            "& th:last-child": {
              width: "20px",
              border: "1px solid blue",
            },
            "& tr th:first-child": {
              width: "20px",
            },
          }}
          muiTableProps={{
            sx: {
              border: "1px solid #eee",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              border: "1px solid #eee",
              whiteSpace: "pre-line",
            },
          }}
          muiTableBodyRowProps={{ hover: false }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "Erro ao tentar carregar lista.",
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTablePaginationProps={{ sx: { backgroundColor: "#FFFFFF" } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: "#FFFFFF" } }}
          muiTopToolbarProps={{
            sx: { backgroundColor: "white", marginBottom: "25px" },
          }}
          muiTableHeadRowProps={{
            sx: {
              background: "#fff",
              "& .MuiTableCell-head": {
                color: "#000",
              },
              border: "0.5px solid #eee",
            },
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: "none" } }}
          manualFiltering
          manualPagination
          manualSorting
          localization={MRT_Localization_PT_BR}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr": {
                backgroundColor: "#FFF",
              },
              "& tr > td": {
                border: "0.5px solid #eee",
              },
            }),
          }}
          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: expandFilters === true ? "2px" : "0px",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton
                      onClick={() => {
                        setExpandFilters((prev) => !prev);
                      }}
                    >
                      <FilterList sx={{ fontSize: 20, color: "#000" }} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={2}>
                        <DatePickerField
                          fullWidth
                          size="small"
                          label="De"
                          value={startDate}
                          onChange={(e) =>
                            setStartDate(
                              e.target.value.split("/").reverse().join("-")
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <DatePickerField
                          fullWidth
                          size="small"
                          label="Até"
                          value={endDate}
                          onChange={(e) =>
                            setEndDate(
                              e.target.value.split("/").reverse().join("-")
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListWallet;
