import styled from "@mui/material/styles/styled";
import { Button } from "@mui/material";



const StyledButton = styled(Button)({
  "&:hover": {
    backgroundColor: "#bf2f37",
  },
});


export default StyledButton;
