import {
  Divider,
  Box,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import StyledButton from "app/components/StyledButton";
import StyledIconButton from "app/components/StyledIconButton";
import { BeneficiaryFormAAccordion } from "./beneficiaryForm";
import { BeneficiaryExtraFormAAccordion } from "./BeneficiaryExtraForm";
import brokersService from "app/services/apis/brokers";
import toast from "react-hot-toast";

const BeneficiaryStep = (props) => {
  const { is_submited } = props;
  useEffect(() => {
    if (props.formik.values?.isBeneficiary) {
      const newBeneficiary = {
        beneficiary_name: props.titular.values.customer_name,
        beneficiary_id_number: props.titular.values.customer_id_num,
        beneficiary_gsm: props.titular.values.customer_gsm,
        beneficiary_gender: props.titular.values.customer_gender,
        beneficiary_birthdate: props.titular.values.customer_birthdate,
        beneficiary_country_id:
          props.titular.values.customer_address_country_id, //pais
        beneficiary_address_zone_id:
          props.titular.values.customer_address_zone_id,
        beneficiary_address_city: props.titular.values.customer_address_city,
        beneficiary_email: props.titular.values.customer_email,
        documents: props.titular.values.documents,
      };

      // chek if beneficiary already inthe list

      const index = props.formik.values.beneficiaries.findIndex(
        (beneficiary) =>
          beneficiary.beneficiary_id_number ===
          props.titular.values.customer_id_num
      );

      if (index !== -1) {
        /* toast.error("beneficiary already in..!") */
      } else {
        props.formik.setFieldValue("beneficiaries", [
          ...props.formik.values.beneficiaries,
          newBeneficiary,
        ]);
      }
    } else {
      const index = props.formik.values.beneficiaries.findIndex(
        (beneficiary) =>
          beneficiary.beneficiary_id_number ===
          props.titular.values.customer_id_num
      );

      if (index !== -1) {
        props.formik.values.beneficiaries.splice(index, 1);
        props.formik.setFieldValue("beneficiaries", [
          ...props.formik.values.beneficiaries,
        ]);
        props.formik.setFieldValue(`isBeneficiary`, false);
      } else {
        console.log("Beneficiário não encontrado no array");
        props.formik.setFieldValue(`isBeneficiary`, false);
      }
    }
  }, [props.formik.values?.isBeneficiary]);

  const MediaMobile = useMediaQuery("(max-width:480px)");
  const MediaDesktop = useMediaQuery("(max-width:480px)");
  return (
    <Grid container spacing={2} paddingTop={1}>
      <Grid item sx={12} sm={12}>
        <Box sx={{ marginTop: "20px", marginBottom: "20px" }} />

        <FormGroup>
          <FormControlLabel
            disabled={
              ((props.formik.values.beneficiaries?.length >=
              props.plan.values.max_family_members
                ? true
                : false) &&
                props.formik.values.isBeneficiary === false) ||
              is_submited
            }
            control={
              <Checkbox
                checked={props.formik.values.isBeneficiary}
                sx={{ color: "#bf2f38" }}
              />
            }
            label={
              <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                <Typography>Titular é beneficiário? </Typography>

                {MediaMobile ? null : (
                  <Typography sx={{ color: "#d35400" }}>
                    ( Serão importados os seus dados como beneficiário)
                  </Typography>
                )}
              </Box>
            }
            onChange={() => {
              props.formik.setFieldValue(
                `isBeneficiary`,
                !props.formik.values?.isBeneficiary
              );
            }}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "18px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
            Beneficiários
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ paddingBottom: "10px", color: "#D35400", fontSize: "14px" }}
          >
            {props.formik.values?.beneficiaries.length}/{" "}
            {props.plan.values.max_family_members}
          </Typography>
        </Box>

        {props.formik.values?.beneficiaries?.map((b, index) => (
          <>
            <BeneficiaryFormAAccordion
              props={props}
              index={index}
              key={index}
              is_submited={is_submited}
            />
            <Box sx={{ marginTop: "5px" }} />
          </>
        ))}
        <Divider sx={{ marginTop: "14px" }} />
      </Grid>

      {props.formik.values?.extras?.length <= 0 ? null : (
        <Grid item xs={12} style={{ marginBottom: "18px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
              Extras
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ paddingBottom: "10px", color: "#D35400", fontSize: "14px" }}
            >
              {props.formik.values?.extras.length} /{" "}
              {props.plan.values.max_extra_members}
            </Typography>
          </Box>
          {props.formik.values?.extras?.map((b, index) => (
            <>
              <BeneficiaryExtraFormAAccordion
                index={index}
                props={props}
                key={index}
                is_submited={is_submited}
              />
              <Box sx={{ marginTop: "5px" }} />
            </>
          ))}
        </Grid>
      )}

      {props.formik.values.beneficiaries.length <
      props.plan.values.max_family_members ? (
        <Grid item xs={12}>
          <StyledIconButton
            sx={{ backgroundColor: "#bf2f38" }}
            disabled={is_submited}
            onClick={() => {
              props.formik.setFieldValue("beneficiaries", [
                ...props.formik.values.beneficiaries,
                {
                  beneficiary_name: "",
                  beneficiary_id_number: "",
                  beneficiary_gsm: "",
                  beneficiary_gender: "",
                  beneficiary_birthdate: "",
                  beneficiary_country_id: 6, //pais
                  beneficiary_address_zone_id: "",
                  beneficiary_address_city: "",
                  beneficiary_email: "",
                  expanded: true,
                  documents: [],
                },
              ]);
            }}
          >
            <Add sx={{ color: "#fff", fontSize: "18px" }} />
          </StyledIconButton>
        </Grid>
      ) : props.formik.values.extras.length <
        props.plan.values.max_extra_members ? (
        !is_submited && (
          <Grid item xs={12}>
            <StyledButton
              sx={{
                backgroundColor: "#bf2f38",
                color: "white",
                fontSize: "12.5px",
                padding: "10px",
                borderRadius: "6px",
              }}
              onClick={() => {
                props.formik.setFieldValue("extras", [
                  ...props.formik.values.extras,
                  {
                    beneficiary_name: "",
                    beneficiary_id_number: "",
                    beneficiary_gsm: "",
                    beneficiary_gender: "",
                    beneficiary_birthdate: "",
                    beneficiary_country_id: 6, //pais
                    beneficiary_address_zone_id: "",
                    beneficiary_address_city: "",
                    beneficiary_email: "",
                    documents: [],
                    expanded: true
                  },
                ]);
              }}
            >
              <Add sx={{ color: "#fff", fontSize: "18px" }} /> Extras
            </StyledButton>
          </Grid>
        )
      ) : (
        !is_submited &&
        props.plan.values.max_extra_members !== null && (
          <Grid item>
            <StyledButton
              disabled
              disableElevation
              sx={{
                backgroundColor: "#b0b0b0",
                color: "white",
                fontSize: "12.5px",
                padding: "10px",
                borderRadius: "6px",
              }}
            >
              Máximo de Extras
            </StyledButton>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default BeneficiaryStep;
